import React, { useState } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  Button,
  Typography,
  MenuItem,
} from "@mui/material";
import { postJsonData } from "../Services/AxoisInterceptorr";
import VisitorEndpoint from "../Services/VisitorEndpoint";
import { toast } from "react-toastify";
import CustomTextField from "./textfield/CoustomTextfield";

const AddMemberPopup = ({ isOpen, onClose }) => {
  const [newMemberData, setNewMemberData] = useState({
    name: "",
    number: "",
    email: "",
    password: "",
    status:"",
  });

  const [validationErrors, setValidationErrors] = useState({
    name: "",
    number: "",
    email: "",
    password: "",
  });

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setNewMemberData((prevData) => ({
      ...prevData,
      [name]: value,
    }));

    validateInput(name, value);
  };

  const validateInput = (name, value) => {
    let error = "";

    switch (name) {
      case "email":
        error = validateEmail(value);
        break;
      case "password":
        error = validatePassword(value);
        break;
      case "number":
        error = validatePhoneNumber(value);
        break;
      default:
        break;
    }

    setValidationErrors((prevErrors) => ({
      ...prevErrors,
      [name]: error,
    }));
  };
  const validatePhoneNumber = (phoneNumber) => {
    const isValidLength = phoneNumber.length === 10;
    const isValidFormat = /^[0-9]+$/.test(phoneNumber);

    if (!isValidLength) {
      return "Phone number must be 10 digits long.";
    }

    if (!isValidFormat) {
      return "Phone number can only contain digits (0-9).";
    }

    return "";
  };
  const validateEmail = (email) => {
    const regex = /^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i;
    return regex.test(email) ? "" : "Please enter valid email address";
  };

  const validatePassword = (password) => {
    const regex =
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/;
    return regex.test(password)
      ? ""
      : "*Please must be at least 8 characters one digit one special character one uppercase.";
  };

  const handleAddMember = (e) => {
    e.preventDefault();

    const isValid = Object.values(newMemberData).every((value, index) => {
      const name = Object.keys(newMemberData)[index];
      const error = validateInput(name, value);
      return error === "";
    });

    if (!isValid) {
      const jsonData = {
        email: newMemberData.email,
        password: newMemberData.password,
        name: newMemberData.name,
        mobileNumber: newMemberData.number,
        role: newMemberData.status,
      };

      postJsonData(
        VisitorEndpoint.CREATE_SUBADMIN,
        jsonData,
        () => {},
        (res) => {
          if (res && res.status === 201) {
            toast.success(res.data.message);
            setTimeout(() => {
              window.location.reload();
            }, 2000);
          }
        },
        (error) => {
          toast.error(error.response.data.message);
        }
      );

      setNewMemberData({
        name: "",
        number: "",
        email: "",
        password: "",
      });
      onClose();
    } else {
      toast.error("Form contains validation errors. Please check.");
    }
  };
  const customTypographyStyles = {
    fontWeight: 600,
    fontSize: "14px",
    fontFamily: '"Montserrat", sans-serif',
    color: "red",
    marginBottom: "10px",
  };
  const allstatus = [
    {
        "_id": "659e3e6afc686b6115760efe",
        "status": "super",
       
    },
    {
        "_id": "659e3e82c0314a9ae17e567a",
        "status": "basic",
      
    },]
  return (
    <Dialog open={isOpen} onClose={onClose}>
      <DialogTitle      sx={{
          textAlign: "center",
          fontFamily: '"Outfit", sans-serif',
          color: "#163e6e",
          fontSize: "20px",
          fontWeight: "600",
        }}>Add Subadmin</DialogTitle>
      <form onSubmit={handleAddMember}>
        <DialogContent>
          <CustomTextField
            label="Name"
            name="name"
            type="text"
            required
            value={newMemberData.name}
            onChange={handleInputChange}
          />

          {validationErrors.name && (
            <Typography sx={customTypographyStyles}>
              {validationErrors.name}
            </Typography>
          )}
          <CustomTextField
            label="Phone"
            name="number"
            type="number"
            required
            value={newMemberData.number}
            onChange={handleInputChange}
          />

          {validationErrors.number && (
            <Typography sx={customTypographyStyles}>
              {validationErrors.number}
            </Typography>
          )}
          <CustomTextField
            label="Email"
            name="email"
            type="text"
            required
            value={newMemberData.email}
            onChange={handleInputChange}
          />

          {validationErrors.email && (
            <Typography sx={customTypographyStyles}>
              {validationErrors.email}
            </Typography>
          )}
          <CustomTextField
            label="Password"
            name="password"
            type="text"
            required
            value={newMemberData.password}
            onChange={handleInputChange}
          />

          {validationErrors.password && (
            <Typography sx={customTypographyStyles}>
              {validationErrors.password}
            </Typography>
          )}

          <CustomTextField
          select
          label="Role"
          name="status"
          required
          value={newMemberData.status}
          onChange={handleInputChange}
          fullWidth
          sx={{ fontFamily: '"Montserrat", sans-serif' }}
        >
         {allstatus.map((stauts) => (
                        <MenuItem
                          key={stauts.status}
                          value={stauts.status}
                          sx={{
                            fontSize: "14px",
                            fontWeight: 500,
                            fontFamily: "Montserrat, sans-serif",
                          }}
                        >
                          {stauts.status}
                        </MenuItem>
                      ))}
     
        </CustomTextField>
        </DialogContent>
        <DialogActions>
          <Button
            style={{ background: "#163e6e" , color: "#FFFFFF" }}
            type="submit"
            variant="contained"
          >
            Add Member
          </Button>
          <Button onClick={onClose} variant="contained" color="secondary">
            Close
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default AddMemberPopup;
