import React, { useState } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  Button,
  Typography,
} from "@mui/material";
import { toast } from "react-toastify";
import { postJsonData } from "../../Services/AxoisInterceptorr";
import VisitorEndpoint from "../../Services/VisitorEndpoint";
import CustomTextField from "../textfield/CoustomTextfield";

const AddCategory = ({ isOpen, onClose }) => {
  const [newMemberData, setNewMemberData] = useState({
    name: "",
  });

  const [validationErrors, setValidationErrors] = useState({
    name: "",
  });

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setNewMemberData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleAddMember = (e) => {
    e.preventDefault();


      const jsonData = {
        contentCategory: newMemberData.name,
      };

      postJsonData(
        VisitorEndpoint.CATEGORY_ADD,
        jsonData,
        () => {},
        (res) => {
          if (res && res.status === 201) {
            toast.success(res.data.message);
            setTimeout(() => {
              window.location.reload();
            }, 2000);
          }
        },
        (error) => {
          toast.error(error.response.data.message);
        }
      );

      setNewMemberData({
        name: "",
      });
      onClose();
    }
  const customTypographyStyles = {
    fontWeight: 600,
    fontSize: "16px",
    fontFamily: '"Montserrat", sans-serif',
    color: "red",
    marginBottom: "10px",
  };
  return (
    <Dialog open={isOpen} onClose={onClose}>
      <DialogTitle
        sx={{
          textAlign: "center",
          fontFamily: '"Outfit", sans-serif',
          color: "#163e6e",
          fontSize: "20px",
          fontWeight: "600",
        }}
      >
        Add Category
      </DialogTitle>
      <form onSubmit={handleAddMember}>
        <DialogContent>
        <CustomTextField
            label="Name"
            name="name"
            type="text"
            required
            value={newMemberData.name}
            onChange={handleInputChange}
          />

          {validationErrors.name && (
            <Typography sx={customTypographyStyles}>
              {validationErrors.name}
            </Typography>
          )}
        </DialogContent>
        <DialogActions>
          <Button
            style={{ background: "#163e6e", color: "#FFFFFF" }}
            type="submit"
            variant="contained"
          >
            Add Member
          </Button>
          <Button onClick={onClose} variant="contained" color="secondary">
            Close
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default AddCategory;
