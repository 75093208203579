


// import  sidebar icons

import marqetinglogosidebar from "../assets/images/marqetinglogosidebar.svg";
import marqetingsmalllogo from "../assets/images/marqetingsmalllogo.svg";
import sidebarmenu from "../assets/images/sidebarmenu.svg";
import caseimages from "../assets/images/caseimages.jpg";
import playbutton from "../assets/images/playbutton.png";
import covervideoimage from "../assets/images/covervideoimage.jpg";













export const MarqetingLogoSidebar = marqetinglogosidebar;
export const MarqetingSmallLogo = marqetingsmalllogo;
export const SidebarMenu = sidebarmenu;
export const Covervideoimage = covervideoimage;
export const Playbutton = playbutton;
export const Caseimages = caseimages;


