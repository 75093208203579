import React, { useState, useEffect } from "react";
import { Route, Navigate, Routes } from "react-router-dom";
import AdminSideBar from "../component/AdminSidebar";
import SubAdminsidebar from "../component/SubAdminsidebar";
import SuperAdminSidebar from "../component/sidebar/SuperAdminSidebar";

const PrivateRoute = ({ ...rest }) => {
  const token = localStorage.getItem("accestoken");
  const userRole = localStorage.getItem("role");


  if (token && userRole === 'super') {
    return (
      <>
        <SuperAdminSidebar>
          {/* <NavBar /> */}
          {rest.element}
        </SuperAdminSidebar>
      </>
    );
  }  else {
    return <Navigate to="/login" />;
  }

};

export default PrivateRoute;
// else if (token && userRole === 'basic') {
//   return (
//     <>
//       <SubAdminsidebar>
//         {rest.element}
//       </SubAdminsidebar>
//     </>
//   );
// }