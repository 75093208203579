import Icon from "../assets/images/camera1.png"
import PeopleIcon from '@mui/icons-material/People';
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts';

export const menuItem = [
    {
      path: "/",
      name: "Brands Details",
      icon: <PeopleIcon sx={{fontSize:"30px"}} />,
    },
    {
      path: "/influncers-details",
      name: "Influncers Details",
      icon: <PeopleIcon sx={{fontSize:"30px"}} />,
    },
    {
      path: "/category",
      name: "Category Details",
      icon: <PeopleIcon sx={{fontSize:"30px"}} />,
    },
    {
      path: "/campaignrequirement",
      name: "Campaign Requirement",
      icon: <PeopleIcon sx={{fontSize:"30px"}} />,
    },
    {
      path: "/casestudies",
      name: "Case Studies",
      icon: <PeopleIcon sx={{fontSize:"30px"}} />,
    },
    {
      path: "/admin",
      name: "Sub Admin",
      icon: <ManageAccountsIcon sx={{fontSize:"30px"}} />,
    },


 
  ];
  
export const menuSubItem = [
    {
      path: "/",
      name: "Visitors Details",
      icon: <PeopleIcon sx={{fontSize:"30px"}} />,
    },
  


 
  ];
  