import React, { useEffect, useState } from "react";
import { NavLink, useLocation } from "react-router-dom";
import "../../style.css";
import {
  MarqetingLogoSidebar,
  MarqetingSmallLogo,
  SidebarMenu,
  //   UserLogout,
  BlueLogout,
} from "../IconsImport";
import { menuItem } from "../Constant";
import LogoutIcon from "@mui/icons-material/Logout";

const SuperAdminSidebar = ({ children }) => {
  const location = useLocation();
  const currentPage = location.pathname;
  const [isOpen, setIsOpen] = useState(false);
  const [isHover, setIsHover] = useState(null);
  const [selectedItem, setSelectedItem] = useState(null);
  const [isLogoutHover, setIsLogoutHover] = useState(false);

  const toggle = () => setIsOpen(!isOpen);
  const logOut = () => {
    localStorage.clear();
  };

  const handleDrawerOpen = () => {
    setIsOpen(true);
  };

  const handleDrawerClose = () => {
    setIsOpen(false);
  };

  useEffect(() => {
    setSelectedItem(currentPage);
  }, [currentPage]);

  return (
    <div className="sidebar_container">
      <div
        style={{
          width: isOpen ? "296px" : "97px",
          borderRadius: isOpen ? "5px" : "0px 20px 20px 0px",
          height: "100vh",
          position: "fixed",
        }}
        className="sidebar_contant"
      >
        <div className="top_section">
          {isOpen && (
            <div style={{ display: "flex", gap: "40px" }}>
              <img
                src={MarqetingLogoSidebar}
                width="166px"
                height="52px"
                alt="logo"
                id="closeButton"
              />
              <img
                src={SidebarMenu}
                alt="SidebarMenu"
                onClick={handleDrawerClose}
                id="closeButton"
              />
            </div>
          )}
          {!isOpen && (
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                gap: "29px",
              }}
            >
              <img
                src={MarqetingSmallLogo}
                width="34px"
                height="30px"
                alt="c_logo"
                id="openButton"
              />

              <img
                src={SidebarMenu}
                alt="SidebarMenu"
                width="27px"
                height="20px"
                onClick={handleDrawerOpen}
                id="openButton"
              />
            </div>
          )}
        </div>
        {menuItem.map((item, index) => (
          <NavLink
            to={item.path}
            key={index}
            className="link"
            onClick={() => setSelectedItem(item.path)}
          >
            <div className="icon">
              <div className="icon-wrapper">{item.icon}</div>
              <div
                style={{ display: isOpen ? "block" : "none" }}
                className="link_text"
              >
                {item.name}
              </div>
            </div>
          </NavLink>
        ))}

        <NavLink
          to="/login"
          className="link"
          activeClassName="active"
          onClick={() => {
            logOut();
          }}
          // onMouseEnter={handleLogoutHover}
          // onMouseLeave={handleLogoutHover}
        >
          <div className="icon_logout">
          
            <LogoutIcon sx={{fontSize:"40px",paddingLeft:"10px"}} />
          </div>
          <div
            style={{ display: isOpen ? "block" : "none" }}
            className="link_text"
          >
            Logout
          </div>
        </NavLink>
      </div>
      <main
        style={{
          marginLeft: isOpen ? "296px" : "97px",
          transition: "margin-left 0.3s ease",
        }}
      >
        {children}
      </main>
    </div>
  );
};

export default SuperAdminSidebar;
