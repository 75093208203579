import { useState, useEffect } from "react";
import { toast } from 'react-toastify';
import VisitorEndpoint from "../../Services/VisitorEndpoint";
import { getAxios } from "../../Services/AxoisInterceptorr";



  

export function useCamapignGoalDetails() {
  const [categoryDetails, setfetchCategoryDetails] = useState([]);
  let fetchedData = false;
  const fetchCamapignGoalDetails = () => {

    const endpoint = VisitorEndpoint.GET_ALL_GOAL;
    const axiosInstance = getAxios();

    axiosInstance
      .get(endpoint)
      .then((response) => {
        console.log("loki",response)
        setfetchCategoryDetails(response.data.data);
      })
      .catch((error) => {
        toast.error(error.response.data.message);
      });
  };
  useEffect(() => {
    if (!fetchedData) {
      fetchCamapignGoalDetails();
      fetchedData = true;
    }
  }, []);


  return categoryDetails;
}
export function useCategoryDetails() {
  const [categoryDetails, setfetchCategoryDetails] = useState([]);
  let fetchedData = false;
  const fetchCategoryDetails = () => {

    const endpoint = VisitorEndpoint.CATEGORY_ALL;
    const axiosInstance = getAxios();

    axiosInstance
      .get(endpoint)
      .then((response) => {
        console.log("loki",response)
        setfetchCategoryDetails(response.data.data);
      })
      .catch((error) => {
        toast.error(error.response.data.message);
      });
  };
  useEffect(() => {
    if (!fetchedData) {
      fetchCategoryDetails();
      fetchedData = true;
    }
  }, []);


  return categoryDetails;
}

export function useBrandDetails() {
  const [brandDetails, setfetchBrandDetails] = useState([]);
  let fetchedData = false;
  const fetchBrandDetails = () => {

    const endpoint = VisitorEndpoint.GET_BRAND_DETAILS;
    const axiosInstance = getAxios();

    axiosInstance
      .get(endpoint)
      .then((response) => {
        console.log("loki",response)
        setfetchBrandDetails(response.data.data);
      })
      .catch((error) => {
        toast.error(error.response.data.message);
      });
  };
  useEffect(() => {
    if (!fetchedData) {
      fetchBrandDetails();
      fetchedData = true;
    }
  }, []);


  return brandDetails;
}
export function useInfluncersDetails() {
  const [influncerDetails, setfetchInfluncersDetails] = useState([]);
  let fetchedData = false;
  const fetchBrandDetails = () => {

    const endpoint = VisitorEndpoint.GET_INFLUNCERS_DETAILS;
    const axiosInstance = getAxios();

    axiosInstance
      .get(endpoint)
      .then((response) => {
        console.log("loki",response)
        setfetchInfluncersDetails(response.data.data);
      })
      .catch((error) => {
        toast.error(error.response.data.message);
      });
  };
  useEffect(() => {
    if (!fetchedData) {
      fetchBrandDetails();
      fetchedData = true;
    }
  }, []);


  return influncerDetails;
}
export function useGetStatus(open) {
  const [allstatus, setAllstatus] = useState([]);
  const fetchuseGetStatus = () => {
    if (open) {
      const endpoint = VisitorEndpoint.ALL_STATUS;
      const axiosInstance = getAxios();

      axiosInstance
        .get(endpoint)
        .then((response) => {
          setAllstatus(response.data.data);
        })
        .catch((error) => {
          toast.error(error.response.data.message);
        });
    }
  };

  useEffect(() => {
    fetchuseGetStatus();
  }, [open]); 

  return allstatus;
}

export function useGetAllCaseStudies() {
  const [allcasedata, setAllCasedata] = useState([]);
  const fetchuseGetAllCase = () => {

    const endpoint = VisitorEndpoint.GETALL_CASE;
    const axiosInstance = getAxios();

    axiosInstance
      .get(endpoint)
      .then((response) => {
        setAllCasedata(response.data.data);
      })
      .catch((error) => {
        toast.error(error.response.data.message);
      });
  };

  useEffect(() => {
    fetchuseGetAllCase();
  }, []);

  return allcasedata;
}
export function useGetAllAdmin() {
  const [alladmindata, setAlladmindata] = useState([]);
  const fetchuseGetAllAdmin = () => {

    const endpoint = VisitorEndpoint.GETALL_SUBADMIN;
    const axiosInstance = getAxios();

    axiosInstance
      .get(endpoint)
      .then((response) => {
        setAlladmindata(response.data.data);
      })
      .catch((error) => {
        toast.error(error.response.data.message);
      });
  };

  useEffect(() => {
    fetchuseGetAllAdmin();
  }, []);

  return alladmindata;
}

export function useDetailsId(id, open) {
  const [detailsId, setDetailsId] = useState();

  const fetchuseDetailsId = () => {
    if (open) {
      const endpoint = `${VisitorEndpoint.GET_VISITORS_DETAILS_BY_ID}/${id}`;
      const axiosInstance = getAxios();

      axiosInstance
        .get(endpoint)
        .then((response) => {
          setDetailsId(response.data.data);
        })
        .catch((error) => {
          toast.error(error.response.data.message);
        });
    }
  };

  useEffect(() => {
    fetchuseDetailsId();
  }, [id, open]);

  return detailsId;
}
export function useBrandDetailsId(id, open) {
  const [detailsId, setDetailsId] = useState();

  const fetchusebrandDetailsId = () => {
    if (open) {
      const endpoint = `${VisitorEndpoint.GET_BRAND_DETAILS_ID}/${id}`;
      const axiosInstance = getAxios();

      axiosInstance
        .get(endpoint)
        .then((response) => {
          setDetailsId(response.data.data);
        })
        .catch((error) => {
          toast.error(error.response.data.message);
        });
    }
  };

  useEffect(() => {
    fetchusebrandDetailsId();
  }, [id, open]);

  return detailsId;
}
export function useInfluncerDetailsId(id, open) {
  const [detailsId, setDetailsId] = useState();

  const fetchuseinfluncerDetailsId = () => {
    if (open) {
      const endpoint = `${VisitorEndpoint.GET_INFLUNCERS_DETAILS_ID}/${id}`;
      const axiosInstance = getAxios();

      axiosInstance
        .get(endpoint)
        .then((response) => {
          setDetailsId(response.data.data);
        })
        .catch((error) => {
          toast.error(error.response.data.message);
        });
    }
  };

  useEffect(() => {
    fetchuseinfluncerDetailsId();
  }, [id, open]);

  return detailsId;
}
export  function  useCaseStudyId(id) {
  const [casestudyId, setCasestudyId] = useState();
  const  fetchuseCaseStudyId = () => {
    const endpoint = `${VisitorEndpoint.CASE_STUDY_ID}/${id}`;
    const axiosInstance = getAxios();
  
    axiosInstance
      .get(endpoint)
      .then((response) => {
       
        setCasestudyId(response.data.data);
      })
      .catch((error) => {
        toast.error(error.response.data.message);
      });
  };

  useEffect(() => {
    fetchuseCaseStudyId();
  }, [id]); 

  return casestudyId;
}