import React, { useEffect, useState } from "react";
import Modal from "@mui/material/Modal";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import Avatar from "@mui/material/Avatar";
import Divider from "@mui/material/Divider";
import CustomReadOnlyTextField from "../ReadonlyInput";
import { useInfluncerDetailsId, usedata } from "../customHooks/customHook";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import Paper from "@mui/material/Paper";
import TableRow from "@mui/material/TableRow";
import CircularProgress from '@mui/material/CircularProgress';


const columns = [
  { id: "socialplatform", label: "Social Platform", minWidth: 200 },
  {
    id: "profileurl",
    label: "profile Url",
    minWidth: 200,
    align: "left",
    format: (value) => value,
  },
  {
    id: "followerscount",
    label: "Followers Count",
    minWidth: 200,
    align: "left",
    format: (value) => value,
  },
];

function createData(socialplatform, profileurl, followerscount) {
  return { socialplatform, profileurl, followerscount };
}
const InfluncersDeatilsModal = ({ open, onClose, userData }) => {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const data = useInfluncerDetailsId(userData, open);
console.log(userData)
  if (!data) {
    return null;
  }
  const rows =
    data &&
    data.socialMedia.map((visitor) =>
      createData(
        visitor.socialPlatform,
        visitor.profileUrl,
        visitor.followersCount
      )
    );
  const selectSpaceData =
    data &&
    data?.contentCategory.map((column) => column.influencerCategory).join(", ");

  if (!data) {
    return null;
  }

  return (
    <Modal open={open} onClose={onClose}>
      <div
        className="modal_user"
        style={{ height: "500px", overflowX: "scroll" }}
      >
        <Card>
          <CardContent>
            <Typography
              sx={{
                fontFamily: '"Outfit", sans-serif',
                color: "#163e6e",
                fontSize: "25px",
                fontWeight: "600",
                textAlign: "center",
              }}
              variant="h5"
              component="div"
              gutterBottom
            >
              Influencer Details
            </Typography>
            {!data ? (
  <CircularProgress />
) : (<><Grid item xs={12} md={8}>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={4}>
                  <CustomReadOnlyTextField
                    id="field1"
                    label="Name:"
                    value={data?.fullName}
                  />
                </Grid>
                <Grid item xs={12} sm={4}>
                  <CustomReadOnlyTextField
                    id="field1"
                    label="Email:"
                    value={data?.email}
                  />
                </Grid>
                <Grid item xs={12} sm={4}>
                  <CustomReadOnlyTextField
                    id="field2"
                    label="Phone:"
                    value={data?.mobileNumber}
                  />
                </Grid>
              </Grid>
              <Divider sx={{ marginY: 1, borderBottom: "none" }} />{" "}
              <Grid container spacing={2}>
                <Grid item xs={12} sm={4}>
                  <CustomReadOnlyTextField
                    id="field4"
                    label="Country:"
                    value={data?.country.name}
                  />
                </Grid>
                <Grid item xs={12} sm={4}>
                  <CustomReadOnlyTextField
                    id="field4"
                    label="State:"
                    value={data?.state.name}
                  />
                </Grid>
                <Grid item xs={12} sm={4}>
                  <CustomReadOnlyTextField
                    id="field4"
                    label="City:"
                    value={data?.city ? data.city.name : "Other City"}
                  />
                </Grid>
              </Grid>
              <Divider sx={{ marginY: 1, borderBottom: "none" }} />
              <Grid container spacing={2}>
                <Grid item xs={12} sm={6}>
                  <CustomReadOnlyTextField
                    id="field"
                    label="Collaboration Preference:"
                    value={data?.collaborationPreference}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <CustomReadOnlyTextField
                    id="field"
                    label="Amount:"
                    value={data?.amount}
                  />
                </Grid>
              </Grid>
              <Divider sx={{ marginY: 1, borderBottom: "none" }} />

              <Grid container spacing={2}>
                {data?.otherCategory.length > 0 && (
                  <Grid item xs={12} sm={6}>
                    <CustomReadOnlyTextField
                      id="field3"
                      label="Other Category:"
                      value={data?.otherCategory}
                    />
                  </Grid>
                )}

                {data?.otherCity && (
                  <Grid item xs={12} sm={6}>
                    <CustomReadOnlyTextField
                      id="field3"
                      label="Other City:"
                      value={data?.otherCity}
                    />
                  </Grid>
                )}
              </Grid>
              <Divider sx={{ marginY: 1, borderBottom: "none" }} />
              <Paper sx={{ width: "100%", overflow: "hidden" }}>
                <TableContainer sx={{ maxHeight: "auto" }}>
                  <Table stickyHeader aria-label="sticky table">
                    <TableHead>
                      <TableRow>
                        {columns.map((column) => (
                          <TableCell
                            key={column.id}
                            align={column.align}
                            style={{
                              minWidth: column.minWidth,
                              backgroundColor: "#ffffff",
                              fontFamily: '"Outfit", sans-serif',
                              color: "#163e6e",
                              fontSize: "16px",
                              fontWeight: "500",
                            }}
                          >
                            {column.label}
                          </TableCell>
                        ))}
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {rows
                        .slice(
                          page * rowsPerPage,
                          page * rowsPerPage + rowsPerPage
                        )
                        .map((row) => {
                          return (
                            <TableRow
                              hover
                              role="checkbox"
                              tabIndex={-1}
                              key={row.code}
                            >
                              {columns.map((column) => {
                                const value = row[column.id];
                                return (
                                  <TableCell
                                    key={column.id}
                                    align={column.align}
                                    style={{
                                      fontFamily: '"Montserrat", sans-serif',
                                      // color: "#000000",
                                      fontSize: "16px",
                                      fontWeight: "500",
                                      color: "#000000",
                                    }}
                                  >
                                    {column.format && typeof value === "number"
                                      ? column.format(value)
                                      : value}
                                  </TableCell>
                                );
                              })}
                            </TableRow>
                          );
                        })}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Paper>
            </Grid>
            <Divider sx={{ marginY: 1, borderBottom: "none" }} />
            <Grid container spacing={1}>
              <Grid item xs={12} sm={12}>
                <CustomReadOnlyTextField
                  id="field8"
                  label="Brand Category:"
                  multiline
                  rows={4}
                  value={selectSpaceData}
                />
              </Grid>
            </Grid>
            </>
            )}

          </CardContent>
          <div style={{ display: "flex", justifyContent: "end" }}>
            <Button
              sx={{
                textAlign: "right",
                fontFamily: '"Outfit", sans-serif',
                color: "#901941",
                fontSize: "16px",
                fontWeight: "600",
              }}
              onClick={onClose}
            >
              Close
            </Button>
          </div>
        </Card>
      </div>
    </Modal>
  );
};

export default InfluncersDeatilsModal;
