import React, { useState, useEffect } from "react";
import {
  Grid,
  FormControl,
  TextField,
  FormControlLabel,
  Switch,
  Button,
  InputAdornment,
  IconButton,
} from "@mui/material";
import { PATTERNS } from "../component/validetion";
import { validateInput } from "../component/validetionUtility";
import { useNavigate } from "react-router-dom";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import Logo from "../assets/images/letsbrimlogo.png";
import { toast } from "react-toastify";
import { postFormData, postJsonData } from "../Services/AxoisInterceptorr";
import VisitorEndpoint from "../Services/VisitorEndpoint";
import { MarqetingLogoSidebar } from "../component/IconsImport";
import ForgatPassPopup from "../common/forgatePassPopup";

const LoginForm = () => {
  const [isEmail, setIsEmail] = useState(false);
  const [email, setEmail] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [otpEntryHidden, setotpEntryHidden] = useState(true);

  const [showPasswordToggle, setShowPasswordToggle] = useState("");

  const [formData, setFormData] = useState({
    email: {
      value: "",
      isValid: true,
    },
    password: {
      value: "",
      isValid: true,
    },
  });
  const [open, setOpen] = useState(false);
  const setValues = (e, field, regEx) => {
    const value = e.target.value;

    // const isValid = regEx ? validateInput(value, regEx) : true;
    const isValid = value.trim() === "" || validateInput(value, regEx);
    setFormData((prevData) => ({
      ...prevData,
      [field]: {
        value: value,
        isValid: isValid,
      },
    }));
  };

  const navigate = useNavigate();

  const handleLogin = (e) => {
    e.preventDefault();

    const isFormValid =
      formData.email.isValid &&
      formData.password.isValid &&
      formData.email.value.trim() !== "" &&
      formData.password.value.trim() !== "";
    if (isFormValid) {
      const data = {
        email: formData.email.value,
        password: formData.password.value,
      };
      postJsonData(
        VisitorEndpoint.LOGIN_ADMIN,
        data,
        () => {},
        (res) => {
          if (res) {
            if (res && res.status === 200) {
              localStorage.setItem("accestoken", res.data.data.accessToken);
              // Extract role from the response
              const role = res.data.data.admin.role;

              // Set the user role in local storage dynamically
              localStorage.setItem("role", role);

              toast.success(res.data.message);
              navigate("/");
            }
          }
        },
        (error) => {
          toast.error(error.response.data.message);
        }
      );
    } else {
      console.log("Form data is not valid.");
    }
  };

  const handleClickShowPassword = () => {
    setShowPasswordToggle(!showPasswordToggle);
  };
  const handleClose = () => {
    setOpen(false);
  };
  const handleForgetPassword = () => {
    const forgetData = {
      email: email,
    };

    postFormData(
      VisitorEndpoint.FORGET_PASSWORD,
      forgetData,
      () => {},
      (res) => {
        if (res && res.status === 200) {
          toast.success(res.data.message);
          handleClose();
        }
      },
      (error) => {
        toast.error("Please enter a registered email address.");
      }
    );
  };
  const handleOpen = () => {

    setOpen(true);
  };

  return (
    <>
     <ForgatPassPopup
        open={open}
        email={email}
        setEmail={setEmail}
        onSubmit={() => handleForgetPassword()}
        onClose={handleClose}
      />
      <div className="container_fluid">
        <div className="container">
          <div className="logo_login">
            <img src={MarqetingLogoSidebar} alt="logo" />
          </div>
          <div className="signup_container">
            <div className="signup_content">
              <h1>Welcome Back!</h1>
              <p className="signup_container_left_typo">
                Please enter your details
              </p>

              <form className="small_media" onSubmit={handleLogin}>
                <div className="login_gap">
                  <>
                    <div className="login_password">
                      <label htmlFor="inputField2" className="input_label">
                        Email{" "}
                        {formData.email.isValid ? null : (
                          <span className="error">*</span>
                        )}
                      </label>
                    </div>

                    <input
                      type="text"
                      id="inputField2"
                      placeholder="xyz@gmail.com"
                      className={`${
                        formData.email.isValid ? "inputStyle" : "inputError"
                      }`}
                      onChange={(e) => {
                        setValues(e, "email", PATTERNS.EMAIL);
                      }}
                      required
                    />
                    {formData.email.isValid || (
                      <p className="input_text_error">
                        *Please enter your valid email address.
                      </p>
                    )}
                  </>
                  <>
                    <div className="login_password">
                      <label htmlFor="inputField3" className="input_label">
                        Password
                        {formData.password.isValid ? null : (
                          <span className="error">*</span>
                        )}
                      </label>
                    </div>

                    <div
                      className={`${
                        formData.password.isValid
                          ? "inputStyleToggle"
                          : "inputErrorToggle"
                      }`}
                    >
                      <input
                        required
                        id="inputField3"
                        type={showPasswordToggle ? "text" : "password"}
                        placeholder="enter password"
                        className="inputpass"
                        onChange={(e) => {
                          setValues(e, "password", PATTERNS.PASSWORD);
                        }}
                      />

                      <InputAdornment position="end">
                        <IconButton
                          sx={{ alignSelf: "center", color: "#163e6e" }}
                          aria-label="toggle password visibility"
                          onClick={handleClickShowPassword}
                        >
                          {showPasswordToggle ? (
                            <Visibility />
                          ) : (
                            <VisibilityOff />
                          )}
                        </IconButton>
                      </InputAdornment>
                    </div>

                    {formData.password.isValid || (
                      <>
                        <p className="input_text_error">
                          *Please enter your password.
                        </p>
                      </>
                    )}
                    <div className="forgot_password" onClick={handleOpen}>
                      <a> Forgot password?</a>
                    </div>
                  </>

                  <button className="signin_button" type="submit">
                    Login
                  </button>
                  {/* <p className="signup_account">
                    Don’t have an account ? <a href="/signup">Sign up</a>
                  </p> */}
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default LoginForm;
