import React, { useEffect, useState } from "react";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import EditIcon from "@mui/icons-material/Edit";
import VisibilityIcon from "@mui/icons-material/Visibility";
import EditModal from "./editModal";
import UserDetailsModal from "./detailsModal/userDetails";
import DeleteIcon from '@mui/icons-material/Delete';
import AddIcon from "@mui/icons-material/Add";
import AddMemberPopup from "./SubAdminpopup";
import { Box, Button, DialogTitle } from "@mui/material";
import { useCamapignGoalDetails, useGetAllAdmin } from "./customHooks/customHook";
import AddCampaignGoal from "./AddModal/AddCampaignGoal";
import { toast } from "react-toastify";
import { deleteJsonData } from "../Services/AxoisInterceptorr";
import VisitorEndpoint from "../Services/VisitorEndpoint";



const columns = [
  { id: "name", label: "Title", minWidth: 200 },
  {
    id: "description",
    label: "Description",
    minWidth: 200,
    align: "left",
    format: (value) => value,
  },


];

function createData(name, description,action) {
  return { name, description, action };
}



export default function CampaignRequirementTabel() {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [addMemberOpen, setAddMemberOpen] = useState(false);
  const [rows, setRows] = useState([]);


const allData = useCamapignGoalDetails();


  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  const handleOpenAddMember = () => {
    setAddMemberOpen(true);
  };

  const handleCloseAddMember = () => {
    setAddMemberOpen(false);
  };

  useEffect(() => {
    if (allData) {
      // Map the fetched data to the table rows
      const tableRows = allData.map((visitor) =>
        createData(visitor.title, visitor.description)
      );
      setRows(tableRows);
    }
  }, [allData]);


  const visitorIds = allData.map((visitor) => visitor._id);
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

 
  const handleDelete = (rowId) => {
    const endpoint =  `${VisitorEndpoint.DELETE_GOAL}/${rowId}`;

    console.log("idd", rowId);
    deleteJsonData(
      endpoint,
      rowId,
      null,
      (res) => {
        console.log("Delete successful", res);
        toast.success(res.data.message);
        setTimeout(() => {
          window.location.reload();
        }, 2000);
        
      },
      (error) => {
        toast.error(error.response.data.message);
      }
    );
  };

  return (
    <>
 
    <Box sx={{display:"flex",justifyContent:"end",margin:"10px 0px" }}>
    <Button style={{background:"#163e6e",color:"#FFFFFF"}} onClick={handleOpenAddMember}>
        <AddIcon />
        Add 
      </Button>
      </Box>

      <AddCampaignGoal
        isOpen={addMemberOpen}
        onClose={handleCloseAddMember}
        // onAddMember={handleAddMember}
      />

      <Paper sx={{ width: "100%", overflow: "hidden" }}>
        <TableContainer sx={{ maxHeight: "auto" }}>
          <Table stickyHeader aria-label="sticky table">
            <TableHead>
              <TableRow>
                {columns.map((column) => (
                  <TableCell
                    key={column.id}
                    align={column.align}
                    style={{
                      minWidth: column.minWidth,
                      backgroundColor: "#e5e5e5",
                      fontFamily: '"Outfit", sans-serif',
                      color: "#163e6e",
                      fontSize: "20px",
                      fontWeight: "600",
                    }}
                  >
                    {column.label}
                  </TableCell>
                ))}
                <TableCell
                  key="actions"
                  align="right"
                  style={{
                    minWidth: 200,
                    backgroundColor: "#e5e5e5",
                    fontFamily: '"Outfit", sans-serif',
                    color: "#163e6e",
                    fontSize: "20px",
                    fontWeight: "600",
                  }}
                >
                  Actions
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {rows
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row,index) => {
                  return (
                    <TableRow
                      hover
                      role="checkbox"
                      tabIndex={-1}
                      key={row.code}
                    >
                      {columns.map((column) => {
                        const value = row[column.id];
                        return (
                          <TableCell
                            key={column.id}
                            align={column.align}
                            style={{
                              fontFamily: '"Montserrat", sans-serif',
                              // color: "#000000",
                              fontSize: "16px",
                              fontWeight: "600",
                              color: "#000000",
                            }}
                          >
                            {column.format && typeof value === "number"
                              ? column.format(value)
                              : value}
                          </TableCell>
                        );
                      })}
                      <TableCell align="right">
                      <DeleteIcon
                          style={{ cursor: "pointer", marginRight: 20 }}
                          onClick={() => handleDelete(visitorIds[index])}                        />
                      </TableCell>
                    </TableRow>
                  );
                })}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[10, 25, 100]}
          component="div"
          count={rows.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          sx={{
            fontFamily: '"Quicksand", sans-serif',
            color: "#000000",
            fontSize: "16px",
            fontWeight: "600",
          }}
        />
      </Paper>
    </>
  );
}
