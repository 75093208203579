import React from "react";
import LoginForm from "../pages/LoginAdmin";
import SubAdminComponent from "../pages/SubAdmin";
import BrandsDetails from "../pages/BrandsDetails";
import InfluncersDetails from "../pages/InfluncersDetails";
import CategoryDetails from "../pages/CategoryDetails";
import CampaignRequirementDetails from "../pages/CampaignRequirement";
import CaseStudies from "../pages/CaseStudies";
import CreateCaseStudies from "../component/caseStudies/CreateCaseStudies";
import CaseStudiesInner from "../component/caseStudies/CaseStudiesInner";
import UpdateCaseStudies from "../component/caseStudies/UpdateCaseStudies";


//Common
// const PageNotFound = React.lazy(() => import("../error/PageNotFound"));
// const ForbiddenAccess = React.lazy(() => import("../error/ForbiddenAccess"));

// login-SignUp
// const Login = React.lazy(() => import("../pages/user/login"));
// const NewUserForm = React.lazy(() => import("../pages/user/NewUserForm"));
// const RecoverAccount = React.lazy(() => import("../pages/user/RecoverAccount"));

// public-Pages

// private pages
// const Opportunities = React.lazy(() => import("../pages/Opportunities/index"));



const configureRoutes = () => {
  const routes = [
    {
      element: <BrandsDetails/>,
      exact: true,
      // path: "/brands-details",
      path: "/",
      title: "Home page",
      type: "private",
    },
    {
      element: <InfluncersDetails/>,
      exact: true,
      path: "/influncers-details",
      title: "Influncers page",
      type: "private",
    },
    {
      element: <LoginForm />,
      exact: true,
      path: "/login",
      title: "Home page",
      type: "public",
    },
    {
      element: <CategoryDetails />,
      exact: true,
      path: "/category",
      title: "Category",
      type: "private",
    },
    {
      element: <CampaignRequirementDetails />,
      exact: true,
      path: "/campaignrequirement",
      title: "Campaign Requirement",
      type: "private",
    },
    {
      element: <SubAdminComponent />,
      exact: true,
      path: "/admin",
      title: "sub admin",
      type: "private",
    },
    {
      element: <CaseStudies />,
      exact: true,
      path: "/casestudies",
      title: "Case Studies",
      type: "private",
    },
    {
      element: <CreateCaseStudies />,
      exact: true,
      path: "/create-casestudies",
      title: "Create Case Studies",
      type: "private",
    },
    {
      element: <UpdateCaseStudies />,
      exact: true,
      path: "/update-casestudies/:id",
      title: "Update Case Studies",
      type: "private",
    },
    {
      element: <CaseStudiesInner />,
      exact: true,
      path: "/casestudies-details/:id",
      title: "Create Case Studies",
      type: "private",
    },


    // {
    //   element: <PageNotFound />,
    //   exact: true,
    //   path: "*",
    //   title: "404 Not Found",
    //   type: "error",
    // },
  ];

  return routes;
};

export default configureRoutes;
