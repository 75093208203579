import React, { useEffect, useState } from "react";
import HeaderComponent from "../component/HeaderComponent";
import StickyHeadTableadmin from "../component/subadminTabel";
import CategoryTabel from "../component/categoryTabel";
import CampaignRequirementTabel from "../component/CampaignRequirementTabel";
import { DialogTitle } from "@mui/material";



const CampaignRequirementDetails = () => {

 

  return (
    <>
      <div className="container_fluid">
        <div className="container">
        <DialogTitle
        sx={{
          textAlign: "center",
          fontFamily: '"Outfit", sans-serif',
          color: "#163e6e",
          fontSize: "30px",
          fontWeight: "600",
        }}
      >
         Campaign Requirement Details
      </DialogTitle>
        <div className="common_container">
      <CampaignRequirementTabel/>
      </div>
        </div>
      </div>
    </>
  );
};

export default CampaignRequirementDetails;
