import React from 'react'
import StickyHeadTable from '../component/styleTabel';
import { DialogTitle } from '@mui/material';
import CaseStudiesTabel from '../component/caseStudies/caseStudiesTabel';

 const CaseStudies = () => {


  return (
    <> <div className="container_fluid">
    <div className="container">
    <DialogTitle
    sx={{
      textAlign: "center",
      fontFamily: '"Outfit", sans-serif',
      color: "#163e6e",
      fontSize: "30px",
      fontWeight: "600",
    }}
  >
     Case Studies Details
  </DialogTitle>
  <div className="common_container">
  <CaseStudiesTabel/>
    </div>
    </div>
  </div></>
  )
}
export default CaseStudies;