import React from "react";
import "./style.css";
import MainRoutes from "./Routes";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

function App() {
  return (
    <>
      <MainRoutes />
      <ToastContainer theme="colored" />
    </>
  );
}

export default App;
