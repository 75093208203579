import { DialogTitle } from '@mui/material';
import React from 'react';

const CustomDialogTitle = ({ children,fontSize,textAlign, ...props }) => (
  <DialogTitle
    sx={{
      fontFamily: '"Outfit", sans-serif',
      color: "#163e6e",
      fontSize: fontSize,
      fontWeight: "600",
      textAlign:textAlign
    }}
    {...props}
  >
    {children}
  </DialogTitle>
);

export default CustomDialogTitle;
