export const currency = (amount) => {
    return amount
      ? amount
          .toLocaleString("en-IN", {
            style: "currency",
            currency: "INR",
          })
          .slice(0, -3)
      : "-";
  };
  
  export const PATTERNS = {
    // PASSWORD: /^(?=.*[0-9])(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$%^&*]{8,24}$/,
    PASSWORD:
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/,
    MPIN: /^(?!(.)\1{3})(?!19|20)\d{4}$/,
    MOBILE: /^[6-9]\d{9}$/,
    MSG:  /^.{15,}$/,
    DTH: /^[0-9]{5,}$/,
    PERSON_NAME: /^([a-z]|[A-Z]|\s){4,}$/,
    // PERSON_NAME: /^([a-z]|[A-Z]|\s){2,}$/,
    FIRST_LAST_NAME: /^[a-zA-Z]+ [a-zA-Z]+$/,
    TEXT_MIN_3: /^[a-zA-Z\s]{3,}$/,
    TEXT: /^[a-zA-Z0-9\s]{1,}$/,
    ALLPHABETS: /^[a-zA-Z\s]{1,}$/,
    BANK_NAME: /^[a-zA-Z\s-]{9,}$/,
    STATE_NAME: /^[a-zA-Z\s-]{3,}$/,
    OPERATOR: /^[a-zA-Z\s-]{3,}$/,
    IFSC: /^([A-Z]{4}0{1}[A-Z0-9]{6})$/,
    ACCOUNT_NUMBER: /^(([A-Z]|[0-9]){5,16})$/,
    SWIFT_CODE: "/^[A-Z]{6}[A-Z0-9]{2}([A-Z0-9]{3})?$/",
    AMT_10_25k:
      /^([1-9]{1}[0-9]{1}|[1-9]{1}[0-9]{2,3}|[1]{1}[0-9]{1}[0-9]{0,3}|[2]{1}[0-4]{1}[0-9]{0,3}|25000)$/,
    AMT_10_5L:
      /^([1-9]{1}[0-9]{1}|[1-9]{1}[0-9]{2,4}|[1-4]{1}[0-9]{2,5}|500000)$/,
    AMT_10_10k: /^([1-9]{1}[0-9]{1}|[1-9]{1}[0-9]{2,3}|10000)$/,
    AMT_02_25k:
      /^([2-9]{1}|[1-9]{1}[0-9]{1}|[1-9]{1}[0-9]{2,3}|[1]{1}[0-9]{1}[0-9]{0,3}|[2]{1}[0-4]{1}[0-9]{0,3}|25000)$/,
    EMAIL: /^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i,
    PAN: /^[A-Z]{5}[0-9]{4}[A-Z]{1}$/,
    GST: /^[0-9]{2}[A-Z]{5}[0-9]{4}[A-Z]{1}[1-9A-Z]{1}Z[0-9A-Z]{1}$/,
    AADHAAR: /^[0-9]{12}$/,
    TEXT_MIN_16: /^[0-9]{16}$/,
    // UPI: /^([\w.-]*[@][\w]*)$/,
    UPI: /^[\w.\-_]{3,}@[a-zA-Z]{3,}$/,
    ALPHA_NUMERICS: /^[A-Za-z0-9]+$/,
    CA_NUMBER: /^[A-Za-z0-9]{6,}$/,
    PINCODE: /^[0-9]{6}$/,
    MINUTES: /^[0-5]?[0-9]$/,
    WEBSITE_URL: /^(https?:\/\/)?([a-zA-Z0-9-]+\.)+[a-zA-Z]{2,6}(\/[a-zA-Z0-9-_.~!*'();:@&=+$,%#/]*)?$/,
  };
  