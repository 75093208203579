import React from "react";
import { Caseimages,Playbutton,Covervideoimage } from "../IconsImport";
import { Grid } from "@mui/material";
import { useParams } from "react-router-dom";
import { useCaseStudyId } from "../customHooks/customHook";
import CustomDialogTitle from "../textfield/CoustomTypography";

const CaseStudiesInner = () => {
  const { id } = useParams();
  const data = useCaseStudyId(id);

  if (!data) {
    return <p>Loading...</p>;
  }


  return (
    <>
      <div className="container_fluid">
        <div className="container">
          <div className="we_have_solution_container case_inner_container">
            <div>
              <CustomDialogTitle fontSize="30px">  {data.title}</CustomDialogTitle>
              
              <div className="common_container">
              <div className="case_studies_inner_container">
                <img
                  className="case_inner_image"
                  src={Caseimages}
                  alt={data.title}
                />
                <p>{data.description}</p>

                <div className="case_studies_inner_container">
                  {data.content &&
                    data.content.map((section, i) => (
                      <div className="case_inner_text" key={i}>
                        {section.heading && <h1>{section.heading}</h1>}
                        {section.image && (
                          <img
                            className="case_inner_image"
                            src={Caseimages}
                            alt="Caseimages"
                          />
                        )}
                        <p>{section.content}</p>
                      </div>
                    ))}
                </div>

                <div className="case_studies_inner_container">
                  {data.imageHeading && <h1>{data.imageHeading}</h1>}
                  <Grid container spacing={2}>
                    {data.imageUrl &&
                      data.imageUrl.map((video, j) => (
                        <Grid item xs={12} sm={3} key={j}>
                          <div className="iphone-frame">
                            <img
                              className="cover_images"
                              src={Covervideoimage}
                              alt="Covervideoimage"
                            />
                            <div className="iphone_mid_content">
                              <a target="_blank" href={video.url}>
                                <img src={Playbutton} alt="Playbutton" />
                              </a>
                            </div>
                          </div>
                        </Grid>
                      ))}
                  </Grid>
                </div>
              </div>
            </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default CaseStudiesInner;
