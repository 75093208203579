import React, { useState } from "react";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import EditIcon from "@mui/icons-material/Edit";
import VisibilityIcon from "@mui/icons-material/Visibility";
import EditModal from "./editModal";
import UserDetailsModal from "./detailsModal/userDetails";
import { useBrandDetails, useVisitorDetails } from "./customHooks/customHook";
import DeleteIcon from "@mui/icons-material/Delete";
import { deleteJsonData } from "../Services/AxoisInterceptorr";
import VisitorEndpoint from "../Services/VisitorEndpoint";
import { toast } from "react-toastify";

const columns = [
  { id: "name", label: "Name", minWidth: 200 },
  {
    id: "number",
    label: "Number",
    minWidth: 200,
    align: "left",
    format: (value) => value,
  },
  {
    id: "email",
    label: "Email",
    minWidth: 200,
    align: "left",
    format: (value) => value,
  },
  {
    id: "brandname",
    label: "BrandName",
    minWidth: 200,
    align: "left",
    format: (value) => value,
  },
];

function createData(name, number, email, brandname, action) {
  return { name, number, email, brandname, action };
}

export default function StickyHeadTable() {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(500);
  const [openEditModal, setOpenEditModal] = useState(false);
  const [selectedEditRow, setSelectedEditRow] = useState(null);

  const [openUserDetailsModal, setOpenUserDetailsModal] = useState(false);
  const [selectedUserDetailsRow, setSelectedUserDetailsRow] = useState();

  const brandDetails = useBrandDetails();

  const rows = brandDetails.map((visitor) =>
    createData(
      visitor.fullName,
      visitor.mobileNumber,
      visitor.email,
      visitor.brandName
    )
  );
  const visitorIds = brandDetails.map((visitor) => visitor._id);
  const handleOpenEditModal = (id) => {
    setSelectedEditRow(id);
    setOpenEditModal(true);
  };

  const handleCloseEditModal = () => {
    setOpenEditModal(false);
  };

  const handleOpenUserDetailsModal = (id) => {
    console.log("id", id);
    setSelectedUserDetailsRow(id);
    setOpenUserDetailsModal(true);
  };

  const handleCloseUserDetailsModal = () => {
    setOpenUserDetailsModal(false);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleDelete = (rowId) => {
    const endpoint = VisitorEndpoint.DELETE_BRANDS;

    console.log("idd", rowId);
    deleteJsonData(
      endpoint,
      rowId,
      null,
      (res) => {
        toast.success(res.data.message);

        setTimeout(() => {
          window.location.reload();
        }, 2000);
      },
      (error) => {
        toast.error(error.response.data.message);
      }
    );
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };
  const handleFormSubmit = (formData) => {
    // Handle form submission logic here
    console.log("Form Data:", formData);
    // Update the row with the new data
    // Example: setSelectedRow((prevRow) => ({ ...prevRow, ...formData }));
  };

  return (
    <>
      <Paper sx={{ width: "100%", overflow: "hidden" }}>
        <TableContainer sx={{ maxHeight: "auto" }}>
          <Table stickyHeader aria-label="sticky table">
            <TableHead>
              <TableRow>
                {columns.map((column) => (
                  <TableCell
                    key={column.id}
                    align={column.align}
                    style={{
                      minWidth: column.minWidth,
                      backgroundColor: "#e5e5e5",
                      fontFamily: '"Outfit", sans-serif',
                      color: "#163e6e",
                      fontSize: "20px",
                      fontWeight: "600",
                    }}
                  >
                    {column.label}
                  </TableCell>
                ))}
                <TableCell
                  key="actions"
                  align="right"
                  style={{
                    minWidth: 200,
                    backgroundColor: "#e5e5e5",
                    fontFamily: '"Outfit", sans-serif',
                    color: "#163e6e",
                    fontSize: "20px",
                    fontWeight: "600",
                  }}
                >
                  Actions
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {rows
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row, index) => {
                  return (
                    <TableRow
                      hover
                      role="checkbox"
                      tabIndex={-1}
                      key={row.code}
                    >
                      {columns.map((column) => {
                        const value = row[column.id];
                        return (
                          <TableCell
                            key={column.id}
                            align={column.align}
                            style={{
                              fontFamily: '"Montserrat", sans-serif',
                              // color: "#000000",
                              fontSize: "16px",
                              fontWeight: "600",
                              color: "#000000",
                            }}
                          >
                            {column.format && typeof value === "number"
                              ? column.format(value)
                              : value}
                          </TableCell>
                        );
                      })}
                      <TableCell align="right">
                        <VisibilityIcon
                          onClick={() =>
                            handleOpenUserDetailsModal(visitorIds[index])
                          }
                          style={{ cursor: "pointer", marginRight: 20 }}
                        />
                        {/* <EditIcon
                          onClick={() => handleOpenEditModal(visitorIds[index])}
                          style={{ cursor: "pointer", marginRight: 20 }}
                        /> */}
                        <DeleteIcon
                          style={{ cursor: "pointer" }}
                          onClick={() => handleDelete(visitorIds[index])}
                        />{" "}
                      </TableCell>
                    </TableRow>
                  );
                })}
            </TableBody>
          </Table>
        </TableContainer>
        {/* <TablePagination
          rowsPerPageOptions={[10, 25, 100]}
          component="div"
          count={rows.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          sx={{
            fontFamily: '"Outfit", sans-serif',
            color: "#000000",
            fontSize: "16px",
            fontWeight: "600",
          }}
        /> */}
      </Paper>
      <EditModal
        open={openEditModal}
        onClose={handleCloseEditModal}
        row={selectedEditRow}
        onFormSubmit={handleFormSubmit}
      />

      {/* UserDetailsModal */}
      <UserDetailsModal
        open={openUserDetailsModal}
        onClose={handleCloseUserDetailsModal}
        userData={selectedUserDetailsRow}
      />
    </>
  );
}
