import React, { useState } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  TextField,
  Button,
  CircularProgress,
  FormControl,
} from "@mui/material";
import { PATTERNS } from "../component/validetion";

const ForgatPassPopup = ({
  open,
  email,
  setEmail,
  loading,
  onSubmit,
  onClose,
}) => {
  const [isValidEmail, setIsValidEmail] = useState(true);
  const handleEmailChange = (e) => {
    const newEmail = e.target.value;
    setEmail(newEmail);

    // Regular expression to validate email format
    const emailPattern = PATTERNS.EMAIL;

    // Check if the new email matches the pattern
    const isValid = newEmail !== "" && emailPattern.test(newEmail);
    setIsValidEmail(isValid);
  };

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle
        sx={{
          color: "#163e6e",
          textAlign: "center",
          fontFamily: '"Outfit", sans-serif',

        }}
      >
        Enter Email
      </DialogTitle>
      <DialogContent>
        <FormControl sx={{ width: "100%" }}>
          <label htmlFor="inputOtp" className="input_label">
            Email
          </label>
          <input
            type="text"
            id="inputField2"
            placeholder="xyz@gmail.com"
            className={isValidEmail ? "inputStyle" : "inputError"}
            onChange={handleEmailChange}
            required
          />
          {!isValidEmail && (
            <p className="input_text_error">*Please enter a valid email.</p>
          )}
        </FormControl>

        <Button
          variant="contained"
          style={{
            backgroundColor: "#163e6e",
            color: "#FFFFFF",
            marginTop: "10px",
          }}
          onClick={() => {
            if (isValidEmail) {
              onSubmit();
            } else {
              alert("Please enter a valid email.");
            }
          }}
          disabled={!isValidEmail}
        >
          {loading ? <CircularProgress size={24} color="inherit" /> : "Submit"}
        </Button>
      </DialogContent>
    </Dialog>
  );
};

export default ForgatPassPopup;
