import React from "react";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";

export default function CustomReadOnlyTextField({
  id,
  label,
  defaultValue,
  multiline,
  rows,
  value,
}) {
  const defaultStyles = {
    color: "#000000",
    fontFamily: '"Montserrat", sans-serif',
    fontSize: "16px",
    fontWeight: 500,
    background:"#FFFFFF"
  };

  const labelStyles = {
    color: "#163e6e",
    fontFamily: '"Outfit", sans-serif',
    fontSize: "20px",
    fontWeight: 500,
  };

  return (
    <Box component="form" noValidate autoComplete="off">
      <TextField
        fullWidth
        size="small"
        id={id}
        label={label}
        defaultValue={defaultValue}
        value={value}
        InputProps={{
          readOnly: true,
          style: defaultStyles,
        }}
        InputLabelProps={{
          style: labelStyles,
        }}
        multiline={multiline} 
        rows={rows} 
        variant="filled"

      />
    </Box>
  );
}
