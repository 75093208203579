import React, { useState } from "react";
import {
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Typography,
  Card,
  CardContent,
  CardActions,
  Grid,
  Divider,
} from "@mui/material";
import { toast } from "react-toastify";
import { postFormData, postJsonData } from "../../Services/AxoisInterceptorr";
import VisitorEndpoint from "../../Services/VisitorEndpoint";
import CustomTextField from "../textfield/CoustomTextfield";
import CustomDialogTitle from "../textfield/CoustomTypography";
import CustomReadOnlyTextField from "../ReadonlyInput";
import MyButton from "../textfield/CoustomButton";
import CustomInputLabel from "../textfield/styledInput";
import { useNavigate } from "react-router-dom";

const CreateCaseStudies = () => {
  const [newMemberData, setNewMemberData] = useState({
    casetitle: "",
    casetype: "",
    description: "",
    coverimage: "",
    headingresult: "",
  });
  const [inputs, setInputs] = useState({});
  const [cards, setCards] = useState([]);
  const [urlcards, setUrlcards] = useState([]);
  console.log(cards);
  const [imageFile, setImageFile] = useState(null);
  const [coverimageFile, setCoverImageFile] = useState(null);
  const [reelimageFile, setReelimageFile] = useState(null);

  const handleImageChange = (e) => {
    const file = e.target.files[0];
    setImageFile(file);
  };
  const handlecoverimages = (e) => {
    const file = e.target.files[0];
    setCoverImageFile(file);
  };
  const handleReelImageChange = (e) => {
    const file = e.target.files[0];
    setReelimageFile(file);
  };
const naviagte = useNavigate();

  const handleChange = (e) => {
    setInputs({ ...inputs, [e.target.name]: e.target.value });
  };
  //   const handleFileChange = (e) => {
  //     const selectedFile = e.target.files[0];
  //     setFile(selectedFile);
  //   };
  // const handleAdd = () => {
  //   setCards([...cards, inputs]);
  //   setInputs({ heading: "", content: "", image: "" });
  // };
  const handleAdd = () => {
    const newCard = {
      heading: inputs.heading,
      content: inputs.content,
      image: imageFile,
    };
    setCards([...cards, newCard]);
    setInputs({ heading: "", content: "" });
    setImageFile(null);
  };
  const handleAddUrl = () => {
    const newReelCard = {
      reelurl: inputs.reelurl,
      contentimg: reelimageFile,
    };
    setUrlcards([...urlcards, newReelCard]);
    setInputs({ headingresult: "", reelurl: "" });
    setReelimageFile(null);
  };

  // const handleAddUrl = () => {
  //   setUrlcards([...urlcards, inputs]);
  //   setInputs({ headingresult: "", contentimg: "", reelurl: "" });
  // };

  const handleRemove = (index) => {
    setCards(cards.filter((_, i) => i !== index));
  };
  const handleRemoveUrl = (index) => {
    setUrlcards(urlcards.filter((_, i) => i !== index));
  };
  const handleRemoveCover = () => {
    setCoverImageFile(null);
  };

  const [validationErrors, setValidationErrors] = useState({
    name: "",
  });

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setNewMemberData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleAddMember = (e) => {
    e.preventDefault();
    // const formattedCards = cards.map((card) => ({
    //   heading: card.heading,
    //   content: card.content,
    //   image: card.image ? card.image.name : null,
    // }));
    // const formattedreelCards = urlcards.map((card) => ({
    //   url: card.reelurl,
    //   image: card.contentimg ? card.contentimg.name : null,
    // }));
    const formData = {
      title: newMemberData.casetitle,
      caseType: newMemberData.casetype,
      coverImage: coverimageFile,
      description: newMemberData.description,
      imageHeading: newMemberData.headingresult,
    };
    const newformData = new FormData();
    Object.keys(formData).forEach((key) => {
      if (key !== "content" && key !== "imageUrl") {
        newformData.append(key, formData[key]);
      }
    });
    cards.forEach((card, index) => {
      newformData.append(`content[${index}][heading]`, card.heading);
      newformData.append(`content[${index}][content]`, card.content);
      if (card.image) {
        newformData.append(
          `content[${index}][image]`,
          card.image,
          card.image.name
        );
      }
    });

    urlcards.forEach((card, index) => {
      newformData.append(`imageUrl[${index}][url]`, card.reelurl);
      if (card.contentimg) {
        newformData.append(
          `imageUrl[${index}][image]`,
          card.contentimg,
          card.contentimg.name
        );
      }
    });

    // formattedCards.forEach((card, index) => {
    //   newformData.append(`content[${index}]`, JSON.stringify(card));
    // });
    // console.log(formattedCards)
    // formattedreelCards.forEach((card, index) => {
    //   newformData.append(`imageUrl[${index}]`, JSON.stringify(card));
    // });

    postFormData(
      VisitorEndpoint.CREATE_CASE_STUDY,
      newformData,
      () => {},
      (res) => {
        if (res && res.status === 201) {
          toast.success(res.data.message);
          setTimeout(() => {
            naviagte("/casestudies")
          }, 3000);
        
        }
      },
      (error) => {
        toast.error(error.response.data.message);
      }
    );

    setNewMemberData({
      name: "",
    });
  };
  
  const customTypographyStyles = {
    fontWeight: 600,
    fontSize: "16px",
    fontFamily: '"Montserrat", sans-serif',
    color: "red",
    marginBottom: "10px",
  };
  return (
    <>
      <div className="container_fluid">
        <div className="container">
          <DialogTitle
            sx={{
              textAlign: "center",
              fontFamily: '"Outfit", sans-serif',
              color: "#163e6e",
              fontSize: "30px",
              fontWeight: "600",
            }}
          >
            Create Case Studies
          </DialogTitle>
          <div className="common_container">
            <form onSubmit={handleAddMember}>
              {/* <Grid container spacing={2}>
                <Grid item xs={12} sm={4}> */}
              <CustomTextField
                label="Case Title"
                name="casetitle"
                type="text"
                value={newMemberData.casetitle}
                onChange={handleInputChange}
              />
              {/* </Grid> */}
              {/* <Grid item xs={12} sm={4}> */}
              <CustomTextField
                label="CaseType"
                name="casetype"
                type="text"
                value={newMemberData.casetype}
                onChange={handleInputChange}
              />
              {/* </Grid> */}
              {/* <Grid item xs={12} sm={4}> */}
              <CustomTextField
                label="Description"
                name="description"
                type="text"
                multiline
                rows={4}
                value={newMemberData.description}
                onChange={handleInputChange}
              />
              {/* </Grid> */}

              {/* </Grid> */}
              {/* <Grid container spacing={2}>
                <Grid item xs={12} sm={6}> */}
              <CustomInputLabel>Cover Image*</CustomInputLabel>
              <CustomTextField
                name="coverimage"
                type="file"
                onChange={handlecoverimages}
              />
              {coverimageFile && (
                <Card>
                  <CardContent>
                    <Divider sx={{ marginY: 1, borderBottom: "none" }} />
                    {coverimageFile && (
                      <img
                        style={{ width: "100px" }}
                        src={URL.createObjectURL(coverimageFile)}
                        alt="Card"
                      />
                    )}
                  </CardContent>
                  <CardActions>
                    <MyButton label="Remove" onClick={handleRemoveCover} />
                  </CardActions>
                </Card>
              )}
              {/* </Grid> */}
              {/* <Grid item xs={12} sm={6}> */}
              <CustomTextField
                name="headingresult"
                type="text"
                label="Campaign Content Heading"
                value={newMemberData.headingresult}
                onChange={handleInputChange}
              />
              {/* </Grid>
              </Grid> */}
              <CustomDialogTitle textAlign="center" fontSize="18px">
                Add New Block
              </CustomDialogTitle>
              {/* <Grid container spacing={2}>
                <Grid item xs={12} sm={4}> */}
              <CustomTextField
                name="heading"
                label="Heading"
                type="text"
                value={inputs.heading}
                onChange={handleChange}
              />
              {/* </Grid> */}

              {/* <Grid item xs={12} sm={4}> */}
              <CustomInputLabel>Campaign Block Image*</CustomInputLabel>
              <CustomTextField
                name="image"
                type="file"
                onChange={handleImageChange}
              />
              {/* </Grid> */}

              {/* <Grid item xs={12} sm={4}> */}
              <CustomTextField
                name="content"
                label="Content"
                type="text"
                multiline
                rows={4}
                value={inputs.content}
                onChange={handleChange}
              />
              {/* </Grid>
              </Grid> */}
              <MyButton label="Add" onClick={handleAdd} />
              <Grid container spacing={2}>
                {cards.map((card, index) => (
                  <Grid item xs={12} sm={3} key={index}>
                    <Card key={index}>
                      <CardContent>
                        <Grid item xs={12} sm={12}>
                          <CustomReadOnlyTextField
                            id="field3"
                            label="Heading:"
                            defaultValue={card.heading}
                          />
                        </Grid>
                        <Divider sx={{ marginY: 1, borderBottom: "none" }} />{" "}
                        <Grid item xs={12} sm={12}>
                          <CustomReadOnlyTextField
                            id="field4"
                            label="Content:"
                            defaultValue={card.content}
                            multiline
                            rows={4}
                          />
                        </Grid>
                        <Divider sx={{ marginY: 1, borderBottom: "none" }} />{" "}
                        {/* <p className="content_scroll">{card.content}</p> */}
                        {card.image && (
                          <img
                            src={URL.createObjectURL(card.image)}
                            alt="Card"
                            className="content_img_case"
                          />
                        )}
                      </CardContent>
                      <CardActions>
                        <MyButton
                          label="Remove"
                          onClick={() => handleRemove(index)}
                        >
                          Remove
                        </MyButton>
                      </CardActions>
                    </Card>
                  </Grid>
                ))}
              </Grid>

              <CustomDialogTitle textAlign="center">
                Add Campaign content
              </CustomDialogTitle>
              {/* <Grid container spacing={2}>
                <Grid item xs={12} sm={6}> */}
              <CustomTextField
                name="reelurl"
                type="text"
                label="Reel URL"
                value={inputs.reelurl}
                onChange={handleChange}
              />
              {/* </Grid> */}
              {/* <Grid item xs={12} sm={6}> */}
              <CustomInputLabel>Campaign Content Image*</CustomInputLabel>

              <CustomTextField
                name="contentimg"
                type="file"
                onChange={handleReelImageChange}
              />
              {/* </Grid>
              </Grid> */}

              <MyButton label="Add" onClick={handleAddUrl} />
              <Grid container spacing={2}>
                {urlcards.map((card, index) => (
                  <Grid item xs={12} sm={3} key={index}>
                    <Card key={index}>
                      <CardContent>
                        <CustomReadOnlyTextField
                          id="field3"
                          label="Reel Url:"
                          defaultValue={card.reelurl}
                        />
                        <Divider sx={{ marginY: 1, borderBottom: "none" }} />{" "}
                        {card.contentimg && (
                          <img
                            src={URL.createObjectURL(card.contentimg)}
                            alt="Card"
                          />
                        )}
                      </CardContent>
                      <CardActions>
                        <MyButton
                          label="Remove"
                          onClick={() => handleRemoveUrl(index)}
                        />
                      </CardActions>
                    </Card>
                  </Grid>
                ))}
              </Grid>
              {/* {validationErrors.name && (
            <Typography sx={customTypographyStyles}>
              {validationErrors.name}
            </Typography>
          )} */}
              <DialogActions>
                <Button
                  style={{ background: "#163e6e", color: "#FFFFFF" }}
                  type="submit"
                  variant="contained"
                >
                  Submit
                </Button>
              </DialogActions>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};

export default CreateCaseStudies;
