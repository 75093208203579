import { TextField } from '@mui/material';
import React from 'react';

const CustomTextField = ({ label,type,required, name, value, onChange, ...props }) => (
  <TextField
    label={label}
    type={type}
    name={name}
    required={required}
    InputProps={{
      style: {
        fontWeight: 500,
        fontSize: "16px",
        fontFamily: '"Montserrat", sans-serif',
        color: "#000000",
      }
    }}
    InputLabelProps={{
      style: {
        fontWeight: 600,
        fontSize: "18px",
        fontFamily: '"Outfit", sans-serif',
        color: "#163e6e",
      }
    }}
    value={value}
    onChange={onChange}
    fullWidth
    margin="normal"
    {...props}
  />
);

export default CustomTextField;
