import React from "react";
// import LandingNavbar from "../components/sidebar/LandingNavbar";
import { Navigate, useLocation } from "react-router-dom";
import AdminSideBar from "../component/AdminSidebar";

const PublicRoute = ({ ...rest }) => {
    const token = localStorage.getItem("accestoken");
    const userRole = localStorage.getItem("role");
    const location = useLocation();

    if (!token) {
        return <>{rest.element}</>;
      } else if (token && userRole === "super") {
        return <Navigate to="/" />;
    }
   
}
export default PublicRoute;
