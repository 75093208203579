import Button from '@mui/material/Button';
import { styled } from '@mui/system';

const CustomButton = styled(Button)({
  fontWeight: 500,
  fontSize: '16px',
  fontFamily: '"Outfit", sans-serif',
  color: '#ffffff',
  background: '#163e6e',
  marginBottom:"10px",
  '&:hover': {
    background: '#163e6e', 
  },
  '&.MuiButton-sizeSmall': {
    fontSize: '12px',
  },
});

const MyButton = ({ label, onClick, ...props }) => (
  <CustomButton size="small" onClick={onClick} {...props}>
    {label}
  </CustomButton>
);

export default MyButton;
