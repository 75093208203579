import React, { useEffect, useState } from "react";
import {
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Typography,
  Card,
  CardContent,
  CardActions,
  Grid,
  Divider,
} from "@mui/material";
import { toast } from "react-toastify";
import {
  postFormData,
  postJsonData,
  putFormData,
} from "../../Services/AxoisInterceptorr";
import VisitorEndpoint from "../../Services/VisitorEndpoint";
import CustomTextField from "../textfield/CoustomTextfield";
import CustomDialogTitle from "../textfield/CoustomTypography";
import CustomReadOnlyTextField from "../ReadonlyInput";
import MyButton from "../textfield/CoustomButton";
import CustomInputLabel from "../textfield/styledInput";
import { useNavigate, useParams } from "react-router-dom";
import { useCaseStudyId } from "../customHooks/customHook";
import { Caseimages } from "../IconsImport";

const UpdateCaseStudies = () => {
  const [newMemberData, setNewMemberData] = useState({
    casetitle: "",
    casetype: "",
    description: "",
    coverimage: "",
    headingresult: "",
  });
  const [editIndex, setEditIndex] = useState(null);
  const [editCoverIndex, setEditCoverIndex] = useState(null);
  const [editReelIndex, setEditReelIndex] = useState(null);

  const [inputs, setInputs] = useState({});
  const [cards, setCards] = useState([]);
  const [urlcards, setUrlcards] = useState([]);
  console.log(cards);
  const [imageFile, setImageFile] = useState(null);
  const [contentimageFile, setContentImageFile] = useState(null);
  const [editreelimageFile, setEditreelimageFile] = useState(null);
  const [coverimageFile, setCoverImageFile] = useState(null);
  const [editcoverimageFile, setEditCoverImageFile] = useState();
  const [reelimageFile, setReelimageFile] = useState(null);
  const [isCardVisible, setIsCardVisible] = useState(true);

  const { id } = useParams();

  const data = useCaseStudyId(id);
  const Content = data?.content;
  const ImageUrl = data?.imageUrl;
  const coverImage = data?.coverImage.imageUrl;
  const [content, setContent] = useState([]);
  const [reelNewUrl, setReelNewUrl] = useState([]);

  useEffect(() => {
    if ((Content, ImageUrl, coverImage)) {
      setContent(Content);
      setReelNewUrl(ImageUrl);
      setEditCoverImageFile(coverImage);
    }
  }, [Content, ImageUrl, coverImage]);
  console.log(editcoverimageFile);
  useEffect(() => {}, [content, reelNewUrl]);

  const handleImageChange = (e) => {
    const file = e.target.files[0];
    setImageFile(file);
  };
  const handleEditReelImageChange = (e) => {
    const file = e.target.files[0];
    setEditreelimageFile(file);
  };
  const handleContentImageChange = (e) => {
    const file = e.target.files[0];
    setContentImageFile(file);
  };
  const handlecoverimages = (e) => {
    const file = e.target.files[0];
    setCoverImageFile(file);
  };
  const handleEditcoverimages = (e) => {
    const file = e.target.files[0];
    setEditCoverImageFile(file);
  };
  const handleReelImageChange = (e) => {
    const file = e.target.files[0];
    setReelimageFile(file);
  };
  const naviagte = useNavigate();

  const handleChange = (e) => {
    setInputs({ ...inputs, [e.target.name]: e.target.value });
  };

  const handleAdd = () => {
    const newCard = {
      heading: inputs.heading,
      content: inputs.content,
      image: imageFile,
    };
    setCards([...cards, newCard]);
    setInputs({ heading: "", content: "" });
    setImageFile(null);
  };
  const handleEdit = (index) => {
    setEditIndex(index);
    console.log(content);
    setInputs({
      _id: content[index]._id,
      heading: content[index].heading,
      content: content[index].content,
      image: content[index].image,
    });
    setContentImageFile(content[index].image || contentimageFile);
  };
  const handleReelEditUrl = (index) => {
    setEditReelIndex(index);
    console.log(reelNewUrl);
    setInputs({
      _id: reelNewUrl[index]._id,
      reelurl: reelNewUrl[index].url,
      image: reelNewUrl[index].image,
    });
    setEditreelimageFile(reelNewUrl[index].image || editreelimageFile);
  };

  const handleSave = () => {
    if (inputs._id) {
      const updatedContent = content.map((item) =>
        item._id === inputs._id
          ? {
              ...item,
              heading: inputs.heading,
              content: inputs.content,
              image: contentimageFile || item.image,
            }
          : item
      );

      setContent(updatedContent);

      setEditIndex(null);
      setInputs({ heading: "", content: "", image: "" });
      setContentImageFile(null);
    } else {
      console.error("No _id found in inputs");
    }
  };

  const handlereelSave = () => {
    if (inputs._id) {
      const updatedContent = reelNewUrl.map((item) =>
        item._id === inputs._id
          ? {
              ...item,
              heading: inputs.heading,

              image: editreelimageFile || item.image,
            }
          : item
      );

      console.log(updatedContent);
      setReelNewUrl(updatedContent);

      setEditReelIndex(null);
      setInputs({ heading: "", content: "", image: "" });
      setEditreelimageFile(null);
    } else {
      console.error("No _id found in inputs");
    }
  };
  const handleReelRemoveUrl = (index) => {
    const updatedContent = [...reelNewUrl];
    updatedContent.splice(index, 1);
    setReelNewUrl(updatedContent);
  };
  const handleRemoveCard = (index) => {
    const updatedContent = [...content];
    updatedContent.splice(index, 1);
    setContent(updatedContent);
  };

  console.log(content, "ik");
  const handleAddUrl = () => {
    const newReelCard = {
      reelurl: inputs.reelurl,
      contentimg: reelimageFile,
    };
    setUrlcards([...urlcards, newReelCard]);
    setInputs({ headingresult: "", reelurl: "" });
    setReelimageFile(null);
  };

  const handleRemove = (index) => {
    setCards(cards.filter((_, i) => i !== index));
  };
  const handleRemoveUrl = (index) => {
    setUrlcards(urlcards.filter((_, i) => i !== index));
  };
  const handleRemoveCover = () => {
    setCoverImageFile(null);
  };

  const [validationErrors, setValidationErrors] = useState({
    name: "",
  });

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setNewMemberData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleAddMember = (e) => {
    e.preventDefault();
    const formData = {
      title: newMemberData.casetitle || data.title,
      caseType: newMemberData.casetype || data.caseType,
      coverImage: coverimageFile || editcoverimageFile,
      description: newMemberData.description || data.description,
      imageHeading: newMemberData.headingresult || data.imageHeading,
    };
    const newformData = new FormData();
    Object.keys(formData).forEach((key) => {
      if (key !== "content" && key !== "imageUrl") {
        newformData.append(key, formData[key]);
      }
    });
    cards.forEach((card, index) => {
      newformData.append(`content[${index}][heading]`, card.heading);
      newformData.append(`content[${index}][content]`, card.content);
      if (card.image) {
        newformData.append(
          `content[${index}][image]`,
          card.image,
          card.image.name
        );
      }
    });

    content.forEach((card, index) => {
      newformData.append(`content[${index}][heading]`, card.heading);
      newformData.append(`content[${index}][content]`, card.content);
      if (
        editIndex == null &&
        card.image &&
        typeof card.image === "object" &&
        card.image.type?.startsWith("image/")
      ) {
        newformData.append(
          `content[${index}][image]`,
          card.image,
          card.image.name
        );
      } else {
        newformData.append(
          `content[${index}][image]`,
          card.image
          // card.image.name
        );
      }
    });

    urlcards.forEach((card, index) => {
      newformData.append(`imageUrl[${index}][url]`, card.reelurl);
      if (card.contentimg) {
        newformData.append(
          `imageUrl[${index}][image]`,
          card.contentimg,
          card.contentimg.name
        );
      }
    });

    reelNewUrl.forEach((card, index) => {
      newformData.append(`imageUrl[${index}][url]`, card.url);
      if (
        editIndex == null &&
        card.image &&
        typeof card.image === "object" &&
        card.image.type?.startsWith("image/")
      ) {
        newformData.append(
          `imageUrl[${index}][image]`,
          card.image,
          card.image.name
        );
      } else {
        newformData.append(
          `imageUrl[${index}][image]`,
          card.image
          // card.image.name
        );
      }
    });

    const endpoint = `${VisitorEndpoint.UPDATE_CASE_STUDY}/${id}`;
    putFormData(
      endpoint,
      newformData,
      () => {},
      (res) => {
        if (res && res.status === 201) {
          toast.success(res.data.message);
          setTimeout(() => {
            naviagte("/casestudies");
          }, 3000);
        }
      },
      (error) => {
        toast.error(error.response.data.message);
      }
    );

    setNewMemberData({
      name: "",
    });
  };
  const handleEditRemoveCover = () => {
    setEditCoverImageFile(null);
    setIsCardVisible(false);
  };
  const handleEditCImages = (index) => {
    setEditCoverIndex(index);
    // setEditCoverImageFile(editcoverimageFile[index]);
    // setEditCoverImageFile(editcoverimageFile);

  };

  
  const handleeditSave = () => {
    const updatedCoverImages = [...editcoverimageFile];
  updatedCoverImages[editCoverIndex] = editcoverimageFile;

  setEditCoverImageFile(updatedCoverImages);

  setEditCoverIndex(null);

};
console.log("oo",editcoverimageFile)
  
  const customTypographyStyles = {
    fontWeight: 600,
    fontSize: "16px",
    fontFamily: '"Montserrat", sans-serif',
    color: "red",
    marginBottom: "10px",
  };
  if (!data) {
    return <p>Loading...</p>;
  }
  return (
    <>
      <div className="container_fluid">
        <div className="container">
          <DialogTitle
            sx={{
              textAlign: "center",
              fontFamily: '"Outfit", sans-serif',
              color: "#163e6e",
              fontSize: "30px",
              fontWeight: "600",
            }}
          >
            Create Case Studies
          </DialogTitle>
          <div className="common_container">
            <form onSubmit={handleAddMember}>
              {/* <Grid container spacing={2}>
                <Grid item xs={12} sm={4}> */}
              <CustomTextField
                label="Case Title"
                name="casetitle"
                type="text"
                value={newMemberData.casetitle || data.title}
                onChange={handleInputChange}
              />
              {/* </Grid> */}
              {/* <Grid item xs={12} sm={4}> */}
              <CustomTextField
                label="CaseType"
                name="casetype"
                type="text"
                value={newMemberData.casetype || data.caseType}
                onChange={handleInputChange}
              />
              {/* </Grid> */}
              {/* <Grid item xs={12} sm={4}> */}
              <CustomTextField
                label="Description"
                name="description"
                type="text"
                multiline
                rows={4}
                value={newMemberData.description || data.description}
                onChange={handleInputChange}
              />
              {/* </Grid> */}

              {/* </Grid> */}
              {/* <Grid container spacing={2}>
                <Grid item xs={12} sm={6}> */}
              {editCoverIndex !== null ? (
                <>
                <CustomInputLabel>Cover Edit Image*</CustomInputLabel>
                <CustomTextField
                  name="covereditimage"
                  type="file"
                  onChange={handleEditcoverimages}
                />
                </>
              ) : (
                <>
                <CustomInputLabel>Cover Image*</CustomInputLabel>
                <CustomTextField
                  name="coverimage"
                  type="file"
                  onChange={handlecoverimages}
                />
                </>
              )}

              {editCoverIndex !== null && <MyButton label="Save" onClick={handleeditSave}/>}
              {coverimageFile && (
                <Card>
                  <CardContent>
                    <Divider sx={{ marginY: 1, borderBottom: "none" }} />
                    {coverimageFile && (
                      <img
                        style={{ width: "100px" }}
                        src={URL.createObjectURL(coverimageFile)}
                        alt="Card"
                      />
                    )}
                  </CardContent>
                  <CardActions>
                    <MyButton label="Remove" onClick={handleRemoveCover} />
                  </CardActions>
                </Card>
              )}
              {isCardVisible && editcoverimageFile && (

              <Card>
                <CardContent>
                  <Divider sx={{ marginY: 1, borderBottom: "none" }} />
                  {editCoverIndex == null &&
                    editcoverimageFile &&
                        typeof editcoverimageFile === "object" &&
                        editcoverimageFile.type?.startsWith("image/") ? (
                          <img
                            src={URL.createObjectURL(editcoverimageFile)}
                            // src={Caseimages}
                            alt="Card"
                            style={{ width: "100px" }}
                          />
                        ) : (
                          <img
                            // src={card.image}
                            src={editcoverimageFile}
                            alt="Card"
                            style={{ width: "100px" }}
                          />
                        )}
                        {/* <img
                            // src={card.image}
                            src={editcoverimageFile}
                            alt="Card"
                            style={{ width: "100px" }}
                          /> */}
                </CardContent>
                <CardActions>
                  <MyButton label="Remove" onClick={handleEditRemoveCover} />
                  <MyButton label="Edit" 
                  onClick={handleEditCImages} 
                

                  />
                </CardActions>
              </Card>
              )}
              {/* </Grid> */}
              {/* <Grid item xs={12} sm={6}> */}
              <CustomTextField
                name="headingresult"
                type="text"
                label="Campaign Content Heading"
                value={newMemberData.headingresult || data.imageHeading}
                onChange={handleInputChange}
              />
              {/* </Grid>
              </Grid> */}
              <CustomDialogTitle textAlign="center" fontSize="18px">
                Add New Block
              </CustomDialogTitle>
              {/* <Grid container spacing={2}>
                <Grid item xs={12} sm={4}> */}
              <CustomTextField
                name="heading"
                label="Heading"
                type="text"
                value={inputs.heading}
                onChange={handleChange}
              />
              {/* </Grid> */}

              {/* <Grid item xs={12} sm={4}> */}
              <CustomInputLabel>Campaign Block Image*</CustomInputLabel>

              {editIndex !== null ? (
                <CustomTextField
                  name="image"
                  type="file"
                  onChange={handleContentImageChange}
                />
              ) : (
                <CustomTextField
                  name="image"
                  type="file"
                  onChange={handleImageChange}
                />
              )}
              {/* </Grid> */}

              {/* <Grid item xs={12} sm={4}> */}
              <CustomTextField
                name="content"
                label="Content"
                type="text"
                multiline
                rows={4}
                value={inputs.content}
                onChange={handleChange}
              />
              {/* </Grid>
              </Grid> */}
              {editIndex !== null ? (
                <MyButton label="Save" onClick={handleSave} />
              ) : (
                <MyButton label="Add" onClick={handleAdd} />
              )}

              <Grid container spacing={2}>
                {cards.map((card, index) => (
                  <Grid item xs={12} sm={3} key={index}>
                    <Card key={index}>
                      <CardContent>
                        <Grid item xs={12} sm={12}>
                          <CustomReadOnlyTextField
                            id="field3"
                            label="Heading:"
                            defaultValue={card.heading}
                          />
                        </Grid>
                        <Divider sx={{ marginY: 1, borderBottom: "none" }} />{" "}
                        <Grid item xs={12} sm={12}>
                          <CustomReadOnlyTextField
                            id="field4"
                            label="Content:"
                            defaultValue={card.content}
                            multiline
                            rows={4}
                          />
                        </Grid>
                        <Divider sx={{ marginY: 1, borderBottom: "none" }} />{" "}
                        {/* <p className="content_scroll">{card.content}</p> */}
                        {card.image && (
                          <img
                            src={URL.createObjectURL(card.image)}
                            alt="Card"
                            className="content_img_case"
                          />
                        )}
                      </CardContent>
                      <CardActions>
                        <MyButton
                          label="Remove"
                          onClick={() => handleRemove(index)}
                        >
                          Remove
                        </MyButton>
                      </CardActions>
                    </Card>
                  </Grid>
                ))}
              </Grid>
              <Grid container spacing={2}>
                {content.map((card, index) => (
                  <Grid item xs={12} sm={3} key={index}>
                    <Card>
                      <CardContent>
                        <Grid item xs={12} sm={12}>
                          <CustomReadOnlyTextField
                            id="field3"
                            label="Heading:"
                            multiline
                            rows={4}
                            value={card.heading}
                          />
                        </Grid>
                        <Divider sx={{ marginY: 1, borderBottom: "none" }} />
                        <Grid item xs={12} sm={12}>
                          <CustomReadOnlyTextField
                            id="field4"
                            label="Content:"
                            value={card.content}
                            multiline
                            rows={4}
                          />
                        </Grid>
                        <Divider sx={{ marginY: 1, borderBottom: "none" }} />{" "}
                        {editIndex == null &&
                        card.image &&
                        typeof card.image === "object" &&
                        card.image.type?.startsWith("image/") ? (
                          <img
                            src={URL.createObjectURL(card.image)}
                            // src={Caseimages}
                            alt="Card"
                            className="content_img_case"
                          />
                        ) : (
                          <img
                            // src={card.image}
                            src={Caseimages}
                            alt="Card"
                            className="content_img_case"
                          />
                        )}
                      </CardContent>
                      <CardActions>
                        <MyButton
                          label="Remove"
                          onClick={() => handleRemoveCard(index)}
                        />
                        <MyButton
                          label="Edit"
                          onClick={() => handleEdit(index)}
                        />
                      </CardActions>
                    </Card>
                  </Grid>
                ))}
              </Grid>

              <CustomDialogTitle textAlign="center">
                Add Campaign content
              </CustomDialogTitle>
              {/* <Grid container spacing={2}>
                <Grid item xs={12} sm={6}> */}
              <CustomTextField
                name="reelurl"
                type="text"
                label="Reel URL"
                value={inputs.reelurl}
                onChange={handleChange}
              />
              {/* </Grid> */}
              {/* <Grid item xs={12} sm={6}> */}
              <CustomInputLabel>Campaign Content Image*</CustomInputLabel>
              {editReelIndex !== null ? (
                <CustomTextField
                  name="contentimg"
                  type="file"
                  onChange={handleEditReelImageChange}
                />
              ) : (
                <CustomTextField
                  name="contentimg"
                  type="file"
                  onChange={handleReelImageChange}
                />
              )}

              {/* </Grid>
              </Grid> */}
              {editReelIndex !== null ? (
                <MyButton label="Save" onClick={handlereelSave} />
              ) : (
                <MyButton label="Add" onClick={handleAddUrl} />
              )}
              <Grid container spacing={2}>
                {urlcards.map((card, index) => (
                  <Grid item xs={12} sm={3} key={index}>
                    <Card key={index}>
                      <CardContent>
                        <CustomReadOnlyTextField
                          id="field3"
                          label="Reel Url:"
                          defaultValue={card.reelurl}
                        />
                        <Divider sx={{ marginY: 1, borderBottom: "none" }} />{" "}
                        {card.contentimg && (
                          <img
                            src={URL.createObjectURL(card.contentimg)}
                            alt="Card"
                          />
                        )}
                      </CardContent>
                      <CardActions>
                        <MyButton
                          label="Remove"
                          onClick={() => handleRemoveUrl(index)}
                        />
                      </CardActions>
                    </Card>
                  </Grid>
                ))}
              </Grid>
              <Grid container spacing={2}>
                {reelNewUrl.map((card, index) => (
                  <Grid item xs={12} sm={3} key={index}>
                    <Card key={index}>
                      <CardContent>
                        <CustomReadOnlyTextField
                          id="field3"
                          label="Reel Url:"
                          value={card.url}
                        />
                        <Divider sx={{ marginY: 1, borderBottom: "none" }} />{" "}
                        {editIndex == null &&
                        card.image &&
                        typeof card.image === "object" &&
                        card.image.type?.startsWith("image/") ? (
                          <img
                            src={URL.createObjectURL(card.image)}
                            alt="Card"
                          />
                        ) : (
                          <img
                            // src={card.imageUrl}
                            src={Caseimages}
                            alt="Card"
                          />
                        )}
                      </CardContent>
                      <CardActions>
                        <MyButton
                          label="Remove"
                          onClick={() => handleReelRemoveUrl(index)}
                        />
                        <MyButton
                          label="Edit"
                          onClick={() => handleReelEditUrl(index)}
                        />
                      </CardActions>
                    </Card>
                  </Grid>
                ))}
              </Grid>

              <DialogActions>
                <Button
                  style={{ background: "#163e6e", color: "#FFFFFF" }}
                  type="submit"
                  variant="contained"
                >
                  Submit
                </Button>
              </DialogActions>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};

export default UpdateCaseStudies;
