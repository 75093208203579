import React from "react";
import Modal from "@mui/material/Modal";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import Avatar from "@mui/material/Avatar";
import Divider from "@mui/material/Divider";
import CustomReadOnlyTextField from "../ReadonlyInput";
import { useBrandDetailsId } from "../customHooks/customHook";

const UserDetailsModal = ({ open, onClose, userData }) => {
  const data = useBrandDetailsId(userData, open);

  const selectSpaceData =
    data &&
    data?.brandCategory.map((column) => column.brandCategory).join(", ");

  if (!data) {
    return null;
  }
  const {
    image,
    name,
    email,
    number,
    status,
    personToMeet,
    numberOfVisitors,
    purpose,
    selectedSpaces,
    numberOfSeats,
    startDate,
  } = userData;

  return (
    <Modal open={open} onClose={onClose}>
      <div
        className="modal_user"
        style={{ height: "500px", overflowX: "scroll" }}
      >
        <Card>
          <CardContent>
            <Typography
              sx={{
                fontFamily: '"Outfit", sans-serif',
                color: "#163e6e",
                fontSize: "25px",
                fontWeight: "600",
                textAlign: "center",
              }}
              variant="h5"
              component="div"
              gutterBottom
            >
              Brands Details
            </Typography>
            <Grid item xs={12} md={8}>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={4}>
                  <CustomReadOnlyTextField
                    id="field1"
                    label="Name:"
                    value={data?.fullName}
                  />
                </Grid>
                <Grid item xs={12} sm={4}>
                  <CustomReadOnlyTextField
                    id="field1"
                    label="Email:"
                    value={data?.email}
                  />
                </Grid>
                <Grid item xs={12} sm={4}>
                  <CustomReadOnlyTextField
                    id="field2"
                    label="Phone:"
                    value={data?.mobileNumber}
                  />
                </Grid>
              </Grid>
              <Divider sx={{ marginY: 1, borderBottom: "none" }} />{" "}
              <Grid container spacing={2}>
                <Grid item xs={12} sm={4}>
                  <CustomReadOnlyTextField
                    id="field4"
                    label="Country:"
                    value={data?.country.name}
                  />
                </Grid>
                <Grid item xs={12} sm={4}>
                  <CustomReadOnlyTextField
                    id="field4"
                    label="State:"
                    value={data?.state.name}
                  />
                </Grid>
                <Grid item xs={12} sm={4}>
                  <CustomReadOnlyTextField
                    id="field4"
                    label="City:"
                    value={data?.city ? data.city.name : "Other City"}
                  />
                </Grid>
              </Grid>
              <Divider sx={{ marginY: 1, borderBottom: "none" }} />
              <Grid container spacing={2}>
                <Grid item xs={12} sm={6}>
                  <CustomReadOnlyTextField
                    id="field3"
                    label="Brand Name:"
                    value={data?.brandName}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <CustomReadOnlyTextField
                    id="field3"
                    label="Brand Website:"
                    value={data?.brandWebsite}
                  />
                </Grid>
              </Grid>
              <Divider sx={{ marginY: 1, borderBottom: "none" }} />
              <Grid container spacing={2}>
                {data?.otherCategory.length > 0 && (
                  <Grid item xs={12} sm={6}>
                    <CustomReadOnlyTextField
                      id="field3"
                      label="Other Category:"
                      value={data?.otherCategory}
                    />
                  </Grid>
                )}

                {data?.otherCity && (
                  <Grid item xs={12} sm={6}>
                    <CustomReadOnlyTextField
                      id="field3"
                      label="Other City:"
                      value={data?.otherCity}
                    />
                  </Grid>
                )}
              </Grid>
              <Divider sx={{ marginY: 1, borderBottom: "none" }} />
              <Grid container spacing={2}>
                <Grid item xs={12} sm={4}>
                  <CustomReadOnlyTextField
                    id="field3"
                    label="Campaign Requirement:"
                    value={data?.campaignRequirement?.title}
                  />
                </Grid>

                <Grid item xs={12} sm={4}>
                  <CustomReadOnlyTextField
                    id="field"
                    label="Collaboration Budget:"
                    value={data?.collaborationBudget}
                  />
                </Grid>
                <Grid item xs={12} sm={4}>
                  <CustomReadOnlyTextField
                    id="field"
                    label="Campaign StartDate:"
                    value={data?.estimatedCampaignStartDate}
                  />
                </Grid>
              </Grid>
              <Divider sx={{ marginY: 1, borderBottom: "none" }} />
              <Grid container spacing={1}>
                <Grid item xs={12} sm={12}>
                  <CustomReadOnlyTextField
                    id="field8"
                    label="Brand Category:"
                    multiline
                    rows={4}
                    value={selectSpaceData}
                  />
                </Grid>
              </Grid>
            </Grid>
          </CardContent>
          <div style={{ display: "flex", justifyContent: "end" }}>
            <Button
              sx={{
                textAlign: "right",
                fontFamily: '"Outfit", sans-serif',
                color: "#901941",
                fontSize: "16px",
                fontWeight: "600",
              }}
              onClick={onClose}
            >
              Close
            </Button>
          </div>
        </Card>
      </div>
    </Modal>
  );
};

export default UserDetailsModal;
