import axios from "axios";
import LRU from "lru-cache";
import useAxios, { configure } from "axios-hooks";
import { BASE_URL } from "./VisitorEndpoint"; 
import { toast } from 'react-toastify';


const cache = new LRU({ max: 10 });

let axiosInstance;
export const getAxios = () => {
  const token = localStorage.getItem("accestoken");
  console.log(process.env.REACT_APP_BASE_URL);
  axiosInstance = axios.create({
    baseURL: BASE_URL,
    timeout: 1500000,
    headers: {
      "Content-Type": "application/json",
      Authorization: `${token}`,
    },
  });
  configure({ axios: axiosInstance, cache });

  axiosInstance.interceptors.request.use(
    function (config) {
      config.headers.Authorization = `${localStorage.getItem("accestoken")}`;
      return config;
    },
    function (error) {
      return Promise.reject(error);
    }
  );

  axiosInstance.interceptors.response.use(
    function (response) {
      return response;
    },
    async function (error) {
      if (
        error &&
        error?.response?.data?.data?.not_valid == "token not valid!"
      ) {
        const oldRequest = error.config;
        oldRequest._retry = true;
        oldRequest.headers["Authorization"] =
          localStorage.getItem("accestoken");
        return axios(oldRequest);
      }

      if (error && error?.response?.data?.message == "Invalid Token") {
        localStorage.clear();
        window.location.reload(false);
      }

      if (error && error?.response?.data?.message == "Token Is Invalid") {
        localStorage.clear();
        window.location.reload(false);
      }
      return Promise.reject(error);
    }
  );

  return axiosInstance;
};
getAxios();
export const useAx = (endpoint) => {
  return useAxios(endpoint);
};
let isTimerActive = false;
const onInValidAuth = (error, next) => {
  if (isTimerActive) {
    return;
  }
  if (error.response && error.response.status === 401) {
    isTimerActive = true;
    localStorage.clear();
    toast.error("Unauthorized Access!");

    setTimeout(() => {
      const location = window.location;
      let baseUrl = location.protocol + "//" + location.host;
      window.open(baseUrl, "_self");
      isTimerActive = false;
    }, 10000);
  }
  if (next) {
    next(error);
  } else {
    toast.error(error);
  }
};


export const postFormData = (
  endpoint,
  formData,
  setIsProgress,
  onSuccess,
  onError
) => {
  if (setIsProgress) setIsProgress(true);
  axiosInstance
    .post(endpoint, formData, {
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: `${localStorage.getItem("accestoken")}`,
      },
    })
    .then((response) => {
      const data = response;
      onSuccess(data);
      if (setIsProgress) setIsProgress(false);
    })
    .catch((error) => {
      if (setIsProgress) setIsProgress(false);
      onInValidAuth(error, onError);
    });
};

export const patchFormData = (
  endpoint,
  formData,
  setIsProgress,
  onSuccess,
  onError
) => {
  if (setIsProgress) setIsProgress(true);
  axiosInstance
    .patch(endpoint, formData, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `${localStorage.getItem("accestoken")}`,
      },
    })
    .then((response) => {
      const data = response;
      onSuccess(data);
      if (setIsProgress) setIsProgress(false);
    })
    .catch((error) => {
      if (setIsProgress) setIsProgress(false);
      onInValidAuth(error, onError);
    });
};
export const putFormData = (
  endpoint,
  formData,
  setIsProgress,
  onSuccess,
  onError
) => {
  if (setIsProgress) setIsProgress(true);
  axiosInstance
    .put(endpoint, formData, {
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: `${localStorage.getItem("accestoken")}`,
      },
    })
    .then((response) => {
      const data = response;
      onSuccess(data);
      if (setIsProgress) setIsProgress(false);
    })
    .catch((error) => {
      if (setIsProgress) setIsProgress(false);
      onInValidAuth(error, onError);
    });
};
export const putJsonData = (
  endpoint,
  formData,
  setIsProgress,
  onSuccess,
  onError
) => {
  if (setIsProgress) setIsProgress(true);
  axiosInstance
    .put(endpoint, formData, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `${localStorage.getItem("accestoken")}`,
      },
    })
    .then((response) => {
      const data = response;
      onSuccess(data);
      if (setIsProgress) setIsProgress(false);
    })
    .catch((error) => {
      if (setIsProgress) setIsProgress(false);
      onInValidAuth(error, onError);
    });
};

export const postJsonData = (
  endpoint,
  jsonData,
  setIsProgress,
  onSuccess,
  onError
) => {
  if (setIsProgress) setIsProgress(true);
  axiosInstance
    .post(endpoint, jsonData, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `${localStorage.getItem("accestoken")}`,

      },
    })
    .then((response) => {
      const data = response;
      onSuccess(data);
      if (setIsProgress) setIsProgress(false);
    })
    .catch((error) => {
      if (setIsProgress) setIsProgress(false);
      onInValidAuth(error, onError);
    });
};

export const deleteJsonData = (
  endpoint,
  id, 
  setIsProgress,
  onSuccess,
  onError
) => {
  if (setIsProgress) setIsProgress(true);
  axiosInstance
    .delete(endpoint, {
      data: { id: id }, 
      headers: {
        "Content-Type": "application/json",
        Authorization: `${localStorage.getItem("accestoken")}`,
      },
    })
    .then((response) => {
      const data = response;
      onSuccess(data);
      if (setIsProgress) setIsProgress(false);
    })
    .catch((error) => {
      if (setIsProgress) setIsProgress(false);
      onInValidAuth(error, onError);
    });
};
