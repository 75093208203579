export const BASE_URL = process.env.REACT_APP_BASE_URL;

const VisitorEndpoint = {
  LOGIN_ADMIN: "/api/v1/admin/login",
  GET_BRAND_DETAILS: "/api/v1/brand/all",
  GET_BRAND_DETAILS_ID: "/api/v1/brand/byid",
  GET_INFLUNCERS_DETAILS: "/api/v1/influencer/all",
  GET_INFLUNCERS_DETAILS_ID: "/api/v1/influencer/byid",
  DELETE_BRANDS: "/api/v1/brand/delete",
  DELETE_INFLUNCERS: "/api/v1/influencer/delete",
  CATEGORY_ALL: "/api/v1/contentcategory/all",
  CATEGORY_DELETE: "/api/v1/contentcategory/delete",
  CATEGORY_ADD: "/api/v1/contentcategory/create",
  CREATE_GOAL: "/api/v1/campaignrequirement/create",
  GET_ALL_GOAL: "/api/v1/campaignrequirement/all",
  DELETE_GOAL: "/api/v1/campaignrequirement/delete",
  CREATE_SUBADMIN: "/api/v1/admin/register",
  GETALL_SUBADMIN: "/api/v1/admin/all",
  DELETE_SUBADMIN: "/api/v1/admin/delete",
  GETALL_CASE: "/api/v1/casestudy/all",
  CASE_STUDY_ID: "api/v1/casestudy/casestudybyid",
  CREATE_CASE_STUDY: "api/v1/casestudy/create",
  DELETE_CASE_STUDY: "api/v1/casestudy/delete",
  UPDATE_CASE_STUDY: "api/v1/casestudy/update",

  

 

};

export default VisitorEndpoint;
